import React, { useState } from 'react'
import SellingStockOpened from './SellingStockOpened';
import SellingStockClosed from './SellingStockClosed';
import { request } from '../../../utils/axios';
import { useQuery } from 'react-query';


const fetchCompanies = async () => {
    const response = await request({
        url: `/companies-data`
    });
    if (response.status === 200) {
        return response?.data;
    }
    return [];
};
const SellingStockContainer = () => {
    const { data, isLoading } = useQuery("companies", fetchCompanies);

    const [openItemId, setOpenItemId] = useState(null);
    const handleToggle = (id) => {
        setOpenItemId(openItemId === id ? null : id);

        
    };
    return (
        <div className='mt-6'>
            {data?.map(item => (
                <div key={item?.id}>
                    {openItemId === item?.id ? (
                        <SellingStockOpened item={item} />
                    ) : (
                        <SellingStockClosed item={item} action={() => handleToggle(item?.id)} isOpen={openItemId === item?.id} />
                    )}
                </div>
            ))}
        </div>
    )
}

export default SellingStockContainer