import ContentWrapper from '../../components/virtualWallet/common/ContentWrapper'
import VirtualButton from '../../components/virtualWallet/common/VirtualButton'
import { useNavigate } from 'react-router-dom'
import VirtualInput from '../../components/virtualWallet/common/VirtualInput'
import SellingStockContainer from '../../components/virtualWallet/sellingInvestements/SellingStockContainer'
import { request } from '../../utils/axios'
import { useQuery } from 'react-query'


// const fetch budget 
const fetchBudget = async () => {
    const response = await request({
        url: `/userBudget`
    });
    if (response.status === 200) {
        return response?.data;
    }
    return [];
};
const SellingInvestments = () => {
    const { data: userBudget } = useQuery("userBudget", fetchBudget);

    return (
        <div>
            <div className="flex gap-x-2 xl:gap-x-8 gap-y-2 flex-col lg:flex-row justify-center">
                <div className="flex gap-y-2 gap-x-2  flex-col md:flex-row justify-center flex-1">
                    <ContentWrapper className={"flex flex-col gap-2 items-center justify-center flex-1"}>

                        <div className='flex items-center gap-3 text-lg'>
                            <span className='text-white bg-mainColor p-1 rounded-lg'>EGP</span>
                            <p className='text-mainColor font-bold'>المبلغ المتاح</p>
                        </div>

                        <h5 className='font-bold text-3xl text-mainColor text-nowrap'> <bdi>{userBudget?.budget} EGP</bdi> </h5>
                    </ContentWrapper>
                    <ButtonContainer />
                </div>

                {/* <InvestmentData /> */}
            </div>
            <p className='mt-5 font-semibold text-base md:text-lg'>إستثماراتي</p>
            <SellingStockContainer />
        </div>

    )
}

const ButtonContainer = () => {
    const navigate = useNavigate();

    return (
        <div className='flex flex-col sm:flex-row md:flex-col items-center justify-around gap-3 '>
            <VirtualButton text="سجل إستثماري" action={() => { navigate("/virtual-wallet/investment-report") }} />
            <VirtualButton text="المحفظة الوهمية" action={() => { navigate("/virtual-wallet") }} />
        </div>
    )
}

const InvestmentData = () => {
    return (
        <ContentWrapper className="flex-1 flex items-center justify-center flex-wrap gap-x-4" >
            <VirtualInput placeholder={"EGP"} label={" قيمة الاستثمار"} value={"1234"} type={"number"} disabled={true} />

            <VirtualInput placeholder={"%"} label={"نسبة العائد "} value={"1233"} type={"number"} disabled={true} />

            <VirtualInput placeholder={"EGP"} label={"القيمة الإجمالية"} value={"1233"} type={"number"} disabled={true} />
        </ContentWrapper>
    )
}
export default SellingInvestments