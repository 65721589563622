import React from 'react'

const InvestmentReportCard = ({ item }) => {

    return (
        <div className='bg-[#ECECEC] rounded-xl shadow-xl md:h-36 relative my-6'>
            <div className='p-2 md:p-6  flex flex-col justify-between h-full'>

                <h5 className='font-bold text-base md:text-lg'>{item?.company}</h5>
                <span className="absolute bg-mainColor text-white left-0 top-0 md:top-[20%] py-1 px-2 rounded-tr-3xl rounded-br-3xl w-[80px] text-[14px] text-center">{item.investment_type}</span>
                <div className='flex items-center justify-between flex-wrap gap-3'>
                    <div className='flex items-center gap-1'>
                        <p className=' text-mainColor font-semibold text-sm md:text-base'>قيمة الاستثمار :</p>
                        <span className='text-xs sm:text-sm md:text-base '>{item?.gain_amount} EGP</span>
                    </div>
                    <div className='flex items-center gap-1'>
                        <p className=' text-mainColor font-semibold text-sm md:text-base'>بمعدل أسهم ت :</p>
                        <span className='text-xs sm:text-sm md:text-base '>{item?.number_of_stocks} سهم </span>
                    </div>
                    {/* // <div className='flex items-center gap-1'>
                    //     <p className=' text-mainColor font-semibold text-sm md:text-base'>نسبة العائد من البيع :</p>
                    //     <span className='text-xs sm:text-sm md:text-base '>% {item.sale_value}</span>
                    // </div>
                    // <div className='flex items-center gap-1'>
                    //     <p className=' text-mainColor font-semibold text-sm md:text-base'>قيمة العائد الاستثماري :</p>
                    //     <span className='text-xs sm:text-sm md:text-base '>{item.buy_value} EGP</span>
                    // </div> */}
                </div>
            </div>
        </div>
    )
}

export default InvestmentReportCard