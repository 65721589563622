import React from "react";

const MainInput = ({ text, handleAction, px, width, bg, fontSize }) => {
  const basicStyle = "text-white  rounded-lg py-2 font-semibold";
  const buttonStyles = ` ${px} ${width} ${bg} ${fontSize}`;

  return (
    <button onClick={handleAction} className={`${basicStyle} ${buttonStyles}`}>
      {text}
    </button>
  );
};

export default MainInput;
