import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CgMenuRightAlt } from "react-icons/cg";
import AOS from "aos";

const titles = [
    // {
    //     name: "الرئيسية",
    //     icon: AiOutlineGlobal,
    //     route: "/community",
    // }
];

const CommunitySidebar = ({ children, action }) => {
    const { pathname } = useLocation();
    const [activeItem, setActiveItem] = useState(pathname);
    const navigate = useNavigate();
    const handleItemClick = (index) => {
        setActiveItem(index);
        navigate(index);
    };

    // ! handle animation
    useEffect(() => {
        AOS.init({
            duration: 850,
        });
    }, []);


    return (
        <div className="flex  flex-col sm:flex-row items-start sm:gap-x-6 md:gap-x-0 mt-20">
            <div className="xl:w-[230px] flex sm:flex-col items-start gap-x-3 gap-y-6 mb-3 sm:mb-0">
                <CgMenuRightAlt className="w-10 h-10 p-1 rounded-lg bg-white text-mainColor cursor-pointer block lg:hidden" onClick={action} data-aos="fade-left"
                />
            </div>
            <div className="w-full">
                {children}
            </div>
        </div>
    );
};

export default CommunitySidebar;
