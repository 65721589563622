import React from 'react';

const ContentPost = ({ text, postImage }) => {

    return (
        <div className='w-full my-2'>
            {postImage !== null &&
                <img src={postImage} alt="post" className='w-full h-[300px] object-contain rounded-xl' />
            }

            <p className='text-[13px] mt-2 p-2'>{text}</p>
        </div>
    )
}

export default ContentPost