import React, { useState } from 'react';
import ContentWrapper from '../virtualWallet/common/ContentWrapper';
import CommunityInput from './core/CommunityInput';
import PostImage from "../../../assets/post_img.svg";
import { request } from '../../utils/axios';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { useNavigate } from "react-router-dom";

const fetchUserProfile = async () => {
    const response = await request({
        url: `/profile`
    });
    if (response.status === 200) {
        return response?.data?.data;
    }
    return [];
};

const CreatePost = () => {
    const { data } = useQuery("userProfile", fetchUserProfile);
    const navigate = useNavigate()
    const [isFocus, setFocus] = useState(false);
    const [post, setPost] = useState({
        user_id: null,
        content: "",
        image: null,
    });

    const handleChange = (e) => {
        const { value } = e.target;
        setPost((prev) => ({
            ...prev,
            content: value
        }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setPost((prev) => ({
            ...prev,
            image: file
        }));
    };

    const storedValue = localStorage.getItem('userInvesto');
    const parsedValue = JSON.parse(storedValue);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { content, image } = post;

        const formData = new FormData();
        formData.append("user_id", parsedValue?.id);
        formData.append("content", content);
        if (image) {
            formData.append("image", image);
        }

        try {
            const response = await request({
                url: "/posts",
                method: "post",
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 200) {
                toast.success("تم انشاء البوست");

                setPost({
                    user_id: null,
                    content: "",
                    image: null,
                });

            } else {
                toast.error("يوجد خطأ ما");
            }
        } catch (error) {
            toast.error("حدث خطأ أثناء إنشاء المنشور");
        }
    };

    return (
        <ContentWrapper className={"min-h-[100px]"}>
            <form onSubmit={handleSubmit}>
                <div className={`flex ${isFocus || post.image ? "items-start" : "items-center"} gap-x-3 md:gap-x-5`}>
                    {/* {
                        data?.Profile_photo === "https://api.tslplatform.com/storage" ? ( */}
                            <div className="relative w-8 h-8 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600 flex items-center justify-center cursor-pointer" onClick={() => navigate(`/community/my-profile/${parsedValue?.id}`)}>
                                <svg onClick={() => navigate(`/community/my-profile/${parsedValue?.id}`)} className="absolute w-8 h-8 text-gray-400 -left-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"></path></svg>
                            </div>
                        {/* ) : (
                            <img src={data?.Profile_photo} alt="" className='w-[35px] h-[35px] md:w-[50px] md:h-[50px] rounded-full object-cover' onClick={() => navigate(`/community/my-profile/${parsedValue?.id}`)} />
                        ) */}
                    {/* } */}
                    <CommunityInput
                        placeholder={"اكتب منشورك........."}
                        name={"content"}
                        value={post?.content}
                        isFocus={isFocus}
                        setFocus={setFocus}
                        action={handleChange}
                    />
                </div>
                <div className='flex items-center gap-1 p-1 border border-[#ECECEC] rounded-lg w-fit cursor-pointer mr-[70px] mt-1'>
                    <img src={PostImage} alt="" className='w-[15px] h-[15px]' />
                    <label htmlFor="file-upload" className='text-[#9B9B9B] cursor-pointer'>صورة</label>
                    <input id="file-upload" type="file" className="hidden" onChange={handleFileChange} />
                </div>
                {post.image && (
                    <div className='mt-2'>
                        <img src={URL.createObjectURL(post.image)} alt="Selected" className='max-w-full h-auto rounded-lg' />
                    </div>
                )}
                <div className={`flex justify-end ${isFocus || post.image ? "mt-3" : "mt-0"} `}>
                    <button type='submit' className='text-center bg-mainColor text-white hover:bg-white border-2
                            hover:border-mainColor hover:text-mainColor duration-700 rounded-lg py-1 px-2 transition-opacity'>
                        إنشاء
                    </button>
                </div>
            </form>
        </ContentWrapper>
    );
};

export default CreatePost;
