import React, { useState } from "react";
import HeroImage from "../../../assets/Photo_2.svg";
import { IoSearchOutline } from "react-icons/io5";
import { useNavigate, Link } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import { useRecommendation } from "../../context/useRecommendationContext";

const Hero = () => {
    return (
        <>
            <div className="mt-28 flex flex-col md:flex-row gap-y-4 items-center justify-evenly ">
                <div>
                    {/* title */}
                    <Title />
                    {/* inputs big screens*/}
                    <div className="hidden lg:flex items-center justify-normal ">
                        <Inputs />
                    </div>
                </div>

                <div>
                    <img
                        src={HeroImage}
                        alt="heroImage"
                        className="w-fit xl:h-fit animateBorder"
                    />
                </div>
            </div>

            {/* inputs small screen*/}
            <div className="flex items-center justify-center lg:hidden ">
                <Inputs />
            </div>
        </>
    );
};

const Title = () => {
    return (
        <>
            <p className="font-bold text-2xl mb-4 ">
                المنصة العربية الأولى لبناء مستقبل استثماري
                <br className="hidden lg:block" />
                ومهني ناجح
            </p>

            <span className="text-black opacity-75">
                نساعدك فى العثور على فرصتك لبناء استثمارك الخاص
            </span>
        </>
    );
};

const Inputs = () => {
    const navigate = useNavigate()
    const storedValue = localStorage.getItem('userInvesto');
    const parsedValue = JSON.parse(storedValue);

    const [userData, setUserData] = useState({
        user_id: parsedValue?.id,
        user_budget: null,
        user_time: null,
        user_risk: null,
    });

    const { request, setResponse } = useRecommendation();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData({ ...userData, [name]: value });
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        const formData = {
            "user_id": userData?.user_id,
            "user_budget": userData?.user_budget,
            "user_time": userData?.user_time,
            "user_risk": userData?.user_risk
        };

        try {
            const response = await request(formData);
            setResponse(response)
            localStorage.setItem('recommendation', JSON.stringify(response?.recommended_Stocks));
            navigate("/charts")
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="flex flex-col gap-y-3 ">
            <form onSubmit={handleOnSubmit}>
                <div className="rounded-3xl md:rounded-full w-full bg-white flex flex-col md:flex-row md:items-center gap-y-3 px-2 md:px-6 py-4 mt-12">
                    <SingleInput
                        label="قيمة الاستثمار"
                        placeholder="المبلغ المراد استثماره"
                        name="user_budget"
                        value={userData.user_budget}
                        onChange={handleChange}
                    />
                    <SingleInput
                        label="نسبة الخسارة"
                        placeholder="نسبة الخسارة المتحملة"
                        value={userData.user_risk}
                        onChange={handleChange}
                        name="user_risk"
                    />
                    <SingleInput
                        label="فترة العائد"
                        placeholder="اختار فترة عائد المكسب"
                        value={userData.user_time}
                        name="user_time"
                        onChange={handleChange}
                    />

                    <button type="submit">
                        <div className="flex justify-end">
                            <div className=" rounded-full bg-mainColor w-10 md:w-16 h-10 md:h-16 flex justify-center items-center cursor-pointer">
                                <IoSearchOutline
                                    size={30}
                                    className="text-white font-bold"
                                />
                            </div>
                        </div>
                    </button>

                </div>
            </form>
            <p>
                هل تحتاج إلي مساعدة لتعلم الإستثمار ؟
                <Link to="/education/courses" className="text-mainColor underline">
                    اطلع على منصتنا التعليمية
                </Link>
            </p>
        </div>
    );
};

const SingleInput = ({ value, label, placeholder, name, onChange }) => {
    return (
        <div>
            <div className="flex flex-col gap-y-2 px-1 md:px-3 ">
                <label htmlFor="investmentValue" className="font-bold">
                    {label}
                </label>
                <input
                    value={value}
                    name={name}
                    type="number"
                    placeholder={placeholder}
                    className="text-xs outline-none"
                    onChange={onChange}
                />
            </div>
        </div>
    );
};

export default Hero;
