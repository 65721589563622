import React from 'react'
import { HiOutlineDotsVertical } from "react-icons/hi";
import { Link, useLocation } from 'react-router-dom';

const PostHeader = ({ avatar, name, created_at, userId }) => {
    const { pathname } = useLocation();

    // Determine the base path
    const basePath = pathname.includes('/community/my-profile') ? '/community/my-profile' : `/community/user-profile/${userId}`;

    return (
        <div className="flex items-center justify-between gap-2 ">
            <Link to={basePath} className='flex items-center gap-[6px] cursor-pointer'>
                {/* {
                    avatar === null ?  */}
                <div className="relative w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                    <svg className="absolute w-12 h-12 text-gray-400 -left-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"></path></svg>
                </div>
                {/*
                     :

                        <img src={avatar} alt="" className='w-[35px] h-[35px] md:w-[50px] md:h-[50px] rounded-full object-cover' />
                } */}
                <div className=''>
                    <p>{name}</p>
                    <span className='text-[#9B9B9B] text-sm'>{created_at}</span>
                </div>
            </Link>
            <HiOutlineDotsVertical className='text-xl cursor-pointer' />
        </div >
    )
}

export default PostHeader