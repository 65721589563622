import React from "react";
import { chartsData } from "../../data/data";

const CompanyShares = ({ response }) => {
  // console.log(response?.recommended_Stocks)
  const storedRecommendation = localStorage.getItem('recommendation');
  const data = JSON.parse(storedRecommendation);
  return (
    <div className="my-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {data?.map((data, i) => {

        return (
          <div key={i} className="bg-white p-4 rounded-2xl ">
            <div className="flex items-center gap-x-3">
              <img src="" alt="" />
              <span className="font-extrabold">{data.company_name}</span>
            </div>
            <div className="grid grid-cols-2 mt-3">
              {/* right col */}

              {/* first line */}
              <div className="">
                <span className="font-semibold">سعر الشراء :</span>
                <p className="text-mainColor font-semibold">
                  {data.Close_price}
                </p>
              </div>

              {/* second line */}
              <div className="my-4 ">
                <span className="font-semibold">  حجم التداول  :</span>
                <p className="text-green-500 font-semibold">
                  {data.Volume}
                </p>
              </div>

              {/* third line */}
              <div className="">
                <span className="">الصناعه  :</span>
                <p className="text-mainColor font-semibold">
                  {data.sector}
                </p>
              </div>


              {/* left col */}

              {/* first line */}
              <div className="">
                <span className="font-semibold"> الأربح الأجله :</span>
                <p className="text-mainColor font-semibold">
                  {data.forward_eps}
                </p>
              </div>

              {/* second line */}
              <div className=" my-4">
                <span className="font-semibold">رمز السهم:</span>
                <p className="text-mainColor font-semibold">
                  {data.symbol}
                </p>
              </div>

              {/* third line */}
              <div className="">
                <span className=""> القينة السوقيه :</span>
                <p className="text-mainColor font-semibold">
                  {data.Market_capital}
                </p>
              </div>

            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CompanyShares;
