import React from "react";

import AuthImage from "../../../assets/BG2.png";
import Logo from "../../../assets/AuthLogo.svg";

const AuthModel = ({ children }) => {
  return (
    <div className="flex justify-center items-center  md:mt-0">
      <div className="flex-auto flex flex-col justify-center items-center    h-screen px-8 gap-y-3 relative auth_model">
        {/* <p className="w-44 h-12 bg-mainColor"></p> */}
        <img src={Logo} alt="logo" className="w-52 h-14" />
        <span className="text-xs sm:text-sm text-center w-64 sm:w-80">
          منصة استثمار تتمثل مهمتنا ف تمكين الأفراد من تحقيق أحلامهم وتحويل
          الفرص الضائعة الى لحظات عزيزة نحو مستقبل أكثر إشراقا وازدهارا
        </span>
        <div> {children}</div>
      </div>

      <img
        src={AuthImage}
        alt="AuthImage"
        className="w-screen h-screen hidden md:block flex-1 rounded-br-[150px]"
      />
    </div>
  );
};

export default AuthModel;
