import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { MdOutlineCancel } from "react-icons/md";
import AuthInput from "./AuthInput";
import { useOutSideClick } from "../../hooks/useOutSideClick";
import {
    confirm_password_validation,
    first_name_validation,
    last_name_validation,
    register_email_validation,
    register_password_validation,
    national_id
} from "../../utils/auth/inputValidations";
import DatePicker from "./DatePicker";
import AuthBtn from "./AuthBtn";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { request } from "../../utils/axios";


const Register = ({ setOpenRegister }) => {
    useEffect(() => {
        AOS.init({
            duration: 500,
            once: true,
        });
    }, []);

    // ! ====== handle close popup functions =======
    const handleCloseRegister = () => {
        setOpenRegister(false);
    };
    const ref = useOutSideClick(handleCloseRegister);

    const handleHeaderClick = (e) => {
        e.stopPropagation();
    };


    // ! ======  handle form functions ========
    const [date, setDate] = useState({
        selectedDate: "",
        selectedMonth: "",
        selectedYear: ""
    })
    const [userData, setUserData] = useState({
        f_name: "",
        l_name: "",
        email: "",
        password: "",
        confirmPass: "",
        national_id: null,
        gender: "male",
        dob: date
    });


    const handleDateChange = (newDate) => {
        setDate(newDate);
        setUserData((prev) => ({
            ...prev,
            dob: newDate,
        }));
    };


    const handleGenderChange = (e) => {
        const { value } = e.target;
        setUserData((prev) => ({
            ...prev,
            gender: value
        }));
    };

    const methods = useForm();
    const onSubmit = methods.handleSubmit(async (data) => {
        const { f_name, l_name, email, password, national_id } = data;

        const formData = new FormData();
        formData.append("f_name", f_name);
        formData.append("l_name", l_name);
        formData.append("email", email);
        formData.append("password", password);
        formData.append("national_id", national_id);
        formData.append("dob", userData.dob);
        formData.append("gender", userData.gender);


        const response = await request({
            url: "/register", method: "post", data: formData
        })

        if (response.status === 200) {
            toast.success("تم إنشاء حساب ، سجل دخولك الأن");
            setOpenRegister(false);
        } else {
            toast.error("يوجد خطأ ما")
        }
    });
    const { f_name, l_name, email, password } = userData;

    return (
        <div className="z-[1000] fixed top-0 bottom-0 right-0 left-0 w-full h-full text-center flex items-center justify-center bg-black bg-opacity-50  overflow-auto cursor-pointer">
            <FormProvider {...methods}>
                <div
                    ref={ref}
                    data-aos="zoom-in"
                    data-aos-once="true"
                    className="w-full sm:w-[550px] mx-5 sm:mx-0 z-[1000] relative   rounded-xl bg-white overflow-auto"
                    onClick={handleHeaderClick}
                >
                    <div className="px-1 py-2 rounded-t-xl bg-mainColor text-white flex items-center">
                        <MdOutlineCancel
                            size={22}
                            onClick={handleCloseRegister}
                            className="cursor-pointer"
                        />

                        <p className="w-[100%] mx-auto">إنشاء حساب</p>
                    </div>
                    <from onSubmit={onSubmit}>
                        <div className="p-3">
                            <div className="flex  justify-between gap-x-2 gap-y-2 ">
                                <AuthInput {...first_name_validation} width="w-full" value={f_name} />
                                <AuthInput {...last_name_validation} width="w-full" value={l_name} />
                            </div>
                            <div className="my-2">
                                <AuthInput {...register_email_validation} width="w-full" value={email} />
                                <AuthInput {...national_id} width="w-full" value={userData.national_id} />

                            </div>
                            <div className="flex  justify-between gap-x-2 gap-y-2  ">
                                <AuthInput {...register_password_validation} width="w-full " value={password} />
                                <AuthInput {...confirm_password_validation} width="w-full " />
                            </div>
                            <div>
                                <DatePicker onDateChange={handleDateChange} date={date} setDate={setDate} />
                            </div>
                            <GenderSelect gender={userData.gender} onChange={handleGenderChange} />

                        </div>
                        <AuthBtn
                            width="lg:w-[50%] w-[80%]"
                            text="إنشاء"
                            action={onSubmit}
                        />
                    </from>
                </div>
            </FormProvider>
        </div>
    );
};

const GenderSelect = ({ gender, onChange }) => {



    return (

        // <AnimatePresence mode="wait" initial={false}>
        <>
            <p className="text-start pb-2">النوع</p>
            <div className="border rounded-xl py-2 border-black cursor-pointer">
                <select
                    value={gender}
                    onChange={onChange}
                    className="w-full px-2 flex items-center justify-between outline-none"
                >

                    <option value="male" selected>ذكر</option>
                    <option value="female">أنثي</option>

                </select>
            </div>
        </>
    )
}

export default Register;
