import React from "react";
import HeroImage from "../../../assets/Photo_2.svg";

const MissionVision = () => {
    return (
        <div className="my-12 flex flex-col md:flex-row gap-y-4 items-center justify-center md:justify-evenly">

            <img
                src={HeroImage}
                alt=""
                className="w-fit xl:h-fit"
                />

            <div className="w-full  lg:w-[50%]">
                <span className="text-mainColor font-bold text-xl">من نحن....؟</span>
                <p className="w-full lg:w-[65%] mt-2">
                منصة استثمار تتمثل مهمتنا ف تمكين الأفراد من تحقيق أحلامهم وتحويل
                الفرص الضائعة الى لحظات عزيزة نحو مستقبل أكثر إشراقا وازدهارا وتهدف
                الى جعل القلوب أفتح والاحلام أكثر اشراقا والحياة أكثر إشباعا.
                </p>
            </div>
        </div>
    );
};

export default MissionVision;
