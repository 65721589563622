import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PiGraduationCap } from "react-icons/pi";
import { IoMdPaper } from "react-icons/io";
import { FiMonitor } from "react-icons/fi";
import AOS from "aos";

const titles = [
    {
        name: "الدورات التعليميه",
        icon: PiGraduationCap,
        route: "/education/courses",
    },
    {   name: "المقالات التعليميه", icon: IoMdPaper, route: "/education/docs" },
    {
        name: "الفيديوهات التعليميه",
        icon: FiMonitor,
        route: "/education/videos",
    },
];

const EducationalSidebar = ({ children }) => {
    const { pathname } = useLocation();
    const [activeItem, setActiveItem] = useState(pathname);
    const navigate = useNavigate();
    const handleItemClick = (index) => {
        setActiveItem(index);
        navigate(index);
    };

    // ! handle animation
    useEffect(() => {
        AOS.init({
            duration: 850,
            });
    }, []);
    const animationDelayFactor = 100;

    return (
        <div className="flex  flex-col sm:flex-row items-start gap-x-6 mt-20">
            <div className="xl:w-[230px] flex sm:flex-col items-start gap-x-3 gap-y-6">
                {titles.map((item, i) => {
                    const Icon = item.icon;
                    
                    return (
                        <div
                        data-aos="fade-left"
                        data-aos-delay={i * animationDelayFactor}
                        key={i}
                        className="flex items-center gap-x-2 cursor-pointer"
                        onClick={() => handleItemClick(item.route)}
                        >
                        
                            <Icon
                                onClick={() => handleItemClick(item.route)}
                                className={`w-10 h-10 p-1  rounded-lg ${
                                item.route === activeItem
                                    ? "bg-mainColor text-white"
                                    : "bg-white opacity-50 shadow text-mainColor"
                                }`}
                            />

                            <Link
                                to={item.route}
                                className={`hidden xl:block text-[.99rem] font-semibold text-mainColor ${
                                item.route === activeItem ? " " : " opacity-50"
                                }`}
                            >
                                {item.name}
                            </Link>

                        </div>
                    );
                    })}
            </div>
            <div className="w-full">
                {children}
            </div>        
        </div>
    );
};

export default EducationalSidebar;
