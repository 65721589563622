import { useNavigate } from "react-router-dom";
import VirtualInput from "../common/VirtualInput";
import StockChart from '../charts/StockChart';
import VirtualButton from '../common/VirtualButton'
import { request } from "../../../utils/axios";
import toast from "react-hot-toast";


const SellingStockOpened = ({ item }) => {

  const actual_timeFrame_int = item?.actual_timeframe.map(item => Math.floor(item));
  const actual_prediction_int = item?.prediction.map(item => Math.floor(item));

  console.log(item);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    // formData.append("company_name", item.company_name);

    try {
      const response = await request({
        url: `/sell/${item?.id}`,
        method: "post",
      });

      if (response.status === 200) {
        toast.success("تم بيع السهم بنجاح");
      } else {
        toast.error("تم بيع السهم");
      }
    } catch (error) {
      toast.error("يوجد خطأ ما");
    }
  };

  return (
    <div className='lg:h-96 bg-white rounded-xl p-3 shadow-lg flex flex-col lg:flex-row items-end gap-5 h-full'>
      <div className='w-full lg:flex-[1]'>
        <StockChart time_frame={actual_timeFrame_int} predicted_values={actual_prediction_int} />
      </div>

      <div className='flex lg:flex-col justify-around items-center h-full gap-5 flex-wrap'>



        <VirtualButton text="  بيع الأن" action={handleSubmit} className={"mt-8"} />


      </div>
    </div>
  )
}

const StockData = () => {
  const navigate = useNavigate()
  return (
    <div className='flex lg:flex-col justify-around items-center h-full gap-5 flex-wrap'>

      {/* <VirtualInput placeholder={"EGP"} label={"سعر الشراء"} value={"1234"} type={"number"} disabled={true} />
      <VirtualInput placeholder={"EGP"} label={"سعر البيع"} value={"1233"} type={"number"} disabled={true} />
      <VirtualInput placeholder={"EGP"} label={"السعر المتوقع"} value={"1233"} type={"number"} disabled={true} /> */}

      <VirtualButton text=" إشتري الأن" action={() => { navigate("/") }} className={"mt-8"} />

    </div>
  )
}
export default SellingStockOpened