import { createSlice } from "@reduxjs/toolkit";

const parseJSON = (key) => {
    try {
        return JSON.parse(window.localStorage.getItem(key));
    } catch (e) {
        return null;
    }
};

const initialState = {
    isLogin: parseJSON("investoAuth") || false,
    userData: parseJSON("userInvesto") || null,
    token: parseJSON("tokenInvesto") || null,
};

const authSlice = createSlice({
    name: "auth-slice",
    initialState,
    reducers: {
        login: (state, action) => {
            state.isLogin = true;
            window.localStorage.setItem("investoAuth", JSON.stringify(state.isLogin));
            state.userData = action.payload.userData;
            localStorage.setItem("userInvesto", JSON.stringify(state.userData));
            state.token = action.payload.token;
            localStorage.setItem("tokenInvesto", JSON.stringify(state.token));
        }
    }
});

export const { login } = authSlice.actions;

export default authSlice.reducer;
 