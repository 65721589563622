import React, { useState } from "react";
import MainInput from "../../components/core/MainInput";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useOutSideClick } from "../../hooks/useOutSideClick";
import { IoPerson } from "react-icons/io5";
import { TbLogout } from "react-icons/tb";
import { request } from "../../utils/axios";
import { useQuery } from "react-query";



const Navbar = () => {
    const navigate = useNavigate();
    const { isLogin } = useSelector((state) => state.authSlice)

    return (
        <div className="fixed top-0 pl-20 py-2 pr-[80px] flex justify-between  w-screen bg-[#ececec] items-center border-b-2 border-mainColor z-40">
            <div className="flex items-center gap-x-2">
                {isLogin ? <AuthNav />
                    :
                    <MainInput
                        text={"login"}
                        handleAction={() => navigate("/login")}
                        px="px-2"
                        width="w-[120px]"
                        bg="bg-mainColor"
                        fontSize="text-lg"
                    />
                }
            </div>

        </div>
    );
};

const AuthNav = () => {
    const navigate = useNavigate();
    const [openMenu, setOpenMenu] = useState(false);

    const handleToggle = () => {
        setOpenMenu(prev => !prev)
    }
    const handleCloseMenu = () => {
        setOpenMenu(false)
    }
    const ref = useOutSideClick(handleCloseMenu);

    // handle logout 
    const handleLogout = () => {
        localStorage.removeItem('investoToken');
        localStorage.removeItem('userInvesto');
        localStorage.removeItem('investoAuth');
        navigate('/');
        window.location.reload();
    }



    const storedValue = localStorage.getItem('userInvesto');
    const parsedValue = JSON.parse(storedValue);

    const fetchUserProfile = async () => {
        const response = await request({
            url: `/profile`
        });
        if (response.status === 200) {
            return response?.data?.data;
        }
        return [];
    }

    const { data } = useQuery("userProfile", fetchUserProfile);

    return (
        <div className="flex items-center gap-x-2 cursor-pointer relative" onClick={handleToggle}>
            <div className="relative w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                <svg className="absolute w-12 h-12 text-gray-400 -left-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"></path></svg>
            </div>
            <h5> {data?.First_name} {data?.Last_name}</h5>
            {openMenu &&
                (
                    <div ref={ref} className="absolute top-[44px] bg-white rounded-lg shadow-md p-2 w-[140px] h-[80px]">
                        <div className=" flex items-center gap-x-[6px] justify-center">
                            <IoPerson siz="20" />
                            <Link to={`/community/my-profile/${parsedValue?.id}`}>
                                حسابي
                            </Link>
                        </div>

                        <div className=" flex items-center gap-x-[6px] justify-center" onClick={handleLogout}>
                            <TbLogout siz="20" />
                            <span>
                                تسجل الخروج
                            </span>
                        </div>
                    </div>
                )
            }

        </div>
    )
}
export default Navbar;
