import CourseCardImg from "../../assets/courseCard.png";
import PlatformAvatar from "../../assets/platFormAvatar.png";

export const texts = [
  "تأمين مستقبل المستثمرين وأفراد الأسرة، حتى يشعر الفرد بالأمان ويوفر لأسرته أكبر قدر من الراحة والأمان يجب عليه أن يوفر لهم دوماً سبل الحماية المادية والمعنوية، والتي يأتي على قمتهم الاستثمار.",
  "  الحصول على التقدير الاجتماعي، حيث إن المستثمرين يلقوا دوماً ترحاب وقبول في المجتمعات.",
  "استمرار التنمية الاقتصادية والحصول على الكثير من الأموال.",
  "حماية رأس المال الأساسي من الضرائب المفردة على كل فرد.",
  "تنمية المجتمع ونهضة البلاد.",
];

export const chartsData = [
  {
    img: "",
    name: "ذهب",
    investMentValue: "15.000 EGP",
    buyPrice: "1200 EGP",
    increaseValue: " 1.5% +",
    sellPrice: "1800 EGP",
    requiredValue: "225 EGP",
    expectedPrice: "2000 EGP",
  },
  {
    img: "",
    name: "أبل",
    investMentValue: "15.000 EGP",
    buyPrice: "1200 EGP",
    increaseValue: " 1.5% +",
    sellPrice: "1800 EGP",
    requiredValue: "225 EGP",
    expectedPrice: "2000 EGP",
  },
  {
    img: "",
    name: "جوجل",
    investMentValue: "15.000 EGP",
    buyPrice: "1200 EGP",
    increaseValue: " 1.5% +",
    sellPrice: "1800 EGP",
    requiredValue: "225 EGP",
    expectedPrice: "2000 EGP",
  },
  {
    img: "",
    name: "ميكروسوفت",
    investMentValue: "15.000 EGP",
    buyPrice: "1200 EGP",
    increaseValue: " 1.5% +",
    sellPrice: "1800 EGP",
    requiredValue: "225 EGP",
    expectedPrice: "2000 EGP",
  },
];

// levels dropdown

export const levels = ["مبتدئ ", "متقدم"];

export const languagesCourse = ["العربيه", "الإنجليزيه", "العربية والانجليزية"];

// ! courses Cards data
export const cardData = [
  {
    img: CourseCardImg,
    accredited: true,
    desc: "دورة على الإنترنت مجانية بعنوان كيف تصبح خبيرًا دوليًا مقدمة من منصة مهارة",
    platform: "منصة مهارة",
    avatar: PlatformAvatar,
    level: "مبتدأ",
    price: "مجانا",
  },
  {
    img: CourseCardImg,
    accredited: false,
    desc: "دورة على الإنترنت مجانية بعنوان كيف تصبح خبيرًا دوليًا مقدمة من منصة مهارة",
    platform: "منصة مهارة",
    avatar: PlatformAvatar,
    level: "مبتدأ",
    price: "مجانا",
  },
  {
    img: CourseCardImg,
    accredited: true,
    desc: "دورة على الإنترنت مجانية بعنوان كيف تصبح خبيرًا دوليًا مقدمة من منصة مهارة",
    platform: "منصة مهارة",
    avatar: PlatformAvatar,
    level: "مبتدأ",
    price: "مجانا",
  },
  {
    img: CourseCardImg,
    accredited: true,
    desc: "دورة على الإنترنت مجانية بعنوان كيف تصبح خبيرًا دوليًا مقدمة من منصة مهارة",
    platform: "منصة مهارة",
    avatar: PlatformAvatar,
    level: "مبتدأ",
    price: "مجانا",
  },
  {
    img: CourseCardImg,
    accredited: false,
    desc: "دورة على الإنترنت مجانية بعنوان كيف تصبح خبيرًا دوليًا مقدمة من منصة مهارة",
    platform: "منصة مهارة",
    avatar: PlatformAvatar,
    level: "مبتدأ",
    price: "مجانا",
  },
  {
    img: CourseCardImg,
    accredited: true,
    desc: "دورة على الإنترنت مجانية بعنوان كيف تصبح خبيرًا دوليًا مقدمة من منصة مهارة",
    platform: "منصة مهارة",
    avatar: PlatformAvatar,
    level: "مبتدأ",
    price: "مجانا",
  },
  {
    img: CourseCardImg,
    accredited: false,
    desc: "دورة على الإنترنت مجانية بعنوان كيف تصبح خبيرًا دوليًا مقدمة من منصة مهارة",
    platform: "منصة مهارة",
    avatar: PlatformAvatar,
    level: "مبتدأ",
    price: "مجانا",
  },
  {
    img: CourseCardImg,
    accredited: true,
    desc: "دورة على الإنترنت مجانية بعنوان كيف تصبح خبيرًا دوليًا مقدمة من منصة مهارة",
    platform: "منصة مهارة",
    avatar: PlatformAvatar,
    level: "مبتدأ",
    price: "مجانا",
  },
  {
    img: CourseCardImg,
    accredited: true,
    desc: "دورة على الإنترنت مجانية بعنوان كيف تصبح خبيرًا دوليًا مقدمة من منصة مهارة",
    platform: "منصة مهارة",
    avatar: PlatformAvatar,
    level: "مبتدأ",
    price: "مجانا",
  },
  {
    img: CourseCardImg,
    accredited: false,
    desc: "دورة على الإنترنت مجانية بعنوان كيف تصبح خبيرًا دوليًا مقدمة من منصة مهارة",
    platform: "منصة مهارة",
    avatar: PlatformAvatar,
    level: "مبتدأ",
    price: "مجانا",
  },
  {
    img: CourseCardImg,
    accredited: true,
    desc: "دورة على الإنترنت مجانية بعنوان كيف تصبح خبيرًا دوليًا مقدمة من منصة مهارة",
    platform: "منصة مهارة",
    avatar: PlatformAvatar,
    level: "مبتدأ",
    price: "مجانا",
  },
  {
    img: CourseCardImg,
    accredited: false,
    desc: "دورة على الإنترنت مجانية بعنوان كيف تصبح خبيرًا دوليًا مقدمة من منصة مهارة",
    platform: "منصة مهارة",
    avatar: PlatformAvatar,
    level: "مبتدأ",
    price: "مجانا",
  },
  {
    img: CourseCardImg,
    accredited: true,
    desc: "دورة على الإنترنت مجانية بعنوان كيف تصبح خبيرًا دوليًا مقدمة من منصة مهارة",
    platform: "منصة مهارة",
    avatar: PlatformAvatar,
    level: "مبتدأ",
    price: "مجانا",
  },
  {
    img: CourseCardImg,
    accredited: true,
    desc: "دورة على الإنترنت مجانية بعنوان كيف تصبح خبيرًا دوليًا مقدمة من منصة مهارة",
    platform: "منصة مهارة",
    avatar: PlatformAvatar,
    level: "مبتدأ",
    price: "مجانا",
  },
  {
    img: CourseCardImg,
    accredited: false,
    desc: "دورة على الإنترنت مجانية بعنوان كيف تصبح خبيرًا دوليًا مقدمة من منصة مهارة",
    platform: "منصة مهارة",
    avatar: PlatformAvatar,
    level: "مبتدأ",
    price: "مجانا",
  },
  {
    img: CourseCardImg,
    accredited: true,
    desc: "دورة على الإنترنت مجانية بعنوان كيف تصبح خبيرًا دوليًا مقدمة من منصة مهارة",
    platform: "منصة مهارة",
    avatar: PlatformAvatar,
    level: "مبتدأ",
    price: "مجانا",
  },
  {
    img: CourseCardImg,
    accredited: false,
    desc: "دورة على الإنترنت مجانية بعنوان كيف تصبح خبيرًا دوليًا مقدمة من منصة مهارة",
    platform: "منصة مهارة",
    avatar: PlatformAvatar,
    level: "مبتدأ",
    price: "مجانا",
  },
  {
    img: CourseCardImg,
    accredited: true,
    desc: "دورة على الإنترنت مجانية بعنوان كيف تصبح خبيرًا دوليًا مقدمة من منصة مهارة",
    platform: "منصة مهارة",
    avatar: PlatformAvatar,
    level: "مبتدأ",
    price: "مجانا",
  },
  {
    img: CourseCardImg,
    accredited: true,
    desc: "دورة على الإنترنت مجانية بعنوان كيف تصبح خبيرًا دوليًا مقدمة من منصة مهارة",
    platform: "منصة مهارة",
    avatar: PlatformAvatar,
    level: "مبتدأ",
    price: "مجانا",
  },
  {
    img: CourseCardImg,
    accredited: false,
    desc: "دورة على الإنترنت مجانية بعنوان كيف تصبح خبيرًا دوليًا مقدمة من منصة مهارة",
    platform: "منصة مهارة",
    avatar: PlatformAvatar,
    level: "مبتدأ",
    price: "مجانا",
  },
  {
    img: CourseCardImg,
    accredited: true,
    desc: "دورة على الإنترنت مجانية بعنوان كيف تصبح خبيرًا دوليًا مقدمة من منصة مهارة",
    platform: "منصة مهارة",
    avatar: PlatformAvatar,
    level: "مبتدأ",
    price: "مجانا",
  },
  {
    img: CourseCardImg,
    accredited: false,
    desc: "دورة على الإنترنت مجانية بعنوان كيف تصبح خبيرًا دوليًا مقدمة من منصة مهارة",
    platform: "منصة مهارة",
    avatar: PlatformAvatar,
    level: "مبتدأ",
    price: "مجانا",
  },
  {
    img: CourseCardImg,
    accredited: true,
    desc: "دورة على الإنترنت مجانية بعنوان كيف تصبح خبيرًا دوليًا مقدمة من منصة مهارة",
    platform: "منصة مهارة",
    avatar: PlatformAvatar,
    level: "مبتدأ",
    price: "مجانا",
  },
  


];

// ! Docs Cards data

export const DocumentsData = [
    {
      id:1,  
      title:"مقدمة عن الاستثمار",
        data:"6 أغسطس 2023" ,
        desc:"يعد الاستثمار من أهم القضايا التي تدور حولها الأبحاث الاقتصادية والعلمية , كما يفتح العديد من النقاشات , كما يفتح العديد من النقاشات "
    },{
      id:2,  
      title:"مقدمة عن الاستثمار",
        data:"6 أغسطس 2023" ,
        desc:"يعد الاستثمار من أهم القضايا التي تدور حولها الأبحاث الاقتصادية والعلمية , كما يفتح العديد من النقاشات , كما يفتح العديد من النقاشات يعد الاستثمار من أهم القضايا التي تدور حولها الأبحاث الاقتصادية والعلمية , كما يفتح العديد من النقاشات , كما يفتح العديد من النقاشات "
    },{
      id:3,  
      title:"مقدمة عن الاستثمار",
        data:"6 أغسطس 2023" ,
        desc:"يعد الاستثماريعد الاستثمار من أهم القضايا التي تدور حولها الأبحاث الاقتصادية والعلمية , كما يفتح العديد من النقاشات , كما يفتح العديد من النقاشات  من أهم القضايا التي تدور حلمية , كما يفتح العديد من النقاشات , كما يفتح العديد من النقاشات "
    },{
      id:4,  
      title:"مقدمة عن الاستثمار",
        data:"6 أغسطس 2023" ,
        desc:"يعد ايعد الاستثمار من أهم القضايا التي تدور حولها الأبحاث الاقتصادية والعلمية , كما يفتح العديد من النقاشات , كما يفتح العديد من النقاشات لاستثمار من أهم القضايا التي تدور حولها الأبحاث الاقتصاديةيعد الاستثمار من أهم القضايا التي تدور حولها الأبحاث الاقتصادية والعلمية , كما يفتح العديد من النقاشات , كما يفتح العديد من النقاشات  والعلمية , كما يفتح العديد من النقاشات , كما يفتح العديد من النقاشات "
    },{
      id:5,  
      title:"مقدمة عن الاستثمار",
        data:"6 أغسطس 2023" ,
        desc:"يعد الاستثمار من أهم القضايا التي تدور حولها الأبحاث الاقتصادية والعلمية , كما يفتح العديد من النقاشات , كما يفتح العديد من النقاشات "
    },{
      id:6,  
      title:"مقدمة عن الاستثمار",
        data:"6 أغسطس 2023" ,
        desc:"يعد الاستثمار من أهم القضايا التي تدور حولها الأبحاث الاقتصادية والعلمية , كما يفتح العديد من النقاشات , كما يفتح العديد من النقاشات "
    },{
      id:7,  
      title:"مقدمة عن الاستثمار",
        data:"6 أغسطس 2023" ,
        desc:"يعد الاستثمار من أهم القضايا التي تدور حولها الأبحاث الاقتصادية والعلمية , كما يفتح العديد من النقاشات , كما يفتح العديد من النقاشات "
    },{
      id:8,  
      title:"مقدمة عن الاستثمار",
        data:"6 أغسطس 2023" ,
        desc:"يعد الاستثمار من أهم القضايا التي تدور حولها الأبحاث الاقتصادية والعلمية , كما يفتح العديد من النقاشات , كما يفتح العديد من النقاشات "
    },{
      id:9,  
      title:"مقدمة عن الاستثمار",
        data:"6 أغسطس 2023" ,
        desc:"يعد الاستثمار من أهم القضايا التي تدور حولها الأبحاث الاقتصادية والعلمية , كما يفتح العديد من النقاشات , كما يفتح العديد من النقاشات "
    },{
      id:10,  
      title:"مقدمة عن الاستثمار",
        data:"6 أغسطس 2023" ,
        desc:"يعد الاستثمار من أهم القضايا التي تدور حولها الأبحاث الاقتصادية والعلمية , كما يفتح العديد من النقاشات , كما يفتح العديد من النقاشات "
    },{
      id:11,  
      title:"مقدمة عن الاستثمار",
        data:"6 أغسطس 2023" ,
        desc:"يعد الاستثمار من أهم القضايا التي تدور حولها الأبحاث الاقتصادية والعلمية , كما يفتح العديد من النقاشات , كما يفتح العديد من النقاشات "
    },{
      id:12,  
      title:"مقدمة عن الاستثمار",
        data:"6 أغسطس 2023" ,
        desc:"يعد الاستثمار من أهم القضايا التي تدور حولها الأبحاث الاقتصادية والعلمية , كما يفتح العديد من النقاشات , كما يفتح العديد من النقاشات "
    },{
      id:13,  
      title:"مقدمة عن الاستثمار",
        data:"6 أغسطس 2023" ,
        desc:"يعد الاستثمار من أهم القضايا التي تدور حولها الأبحاث الاقتصادية والعلمية , كما يفتح العديد من النقاشات , كما يفتح العديد من النقاشات "
    },{
      id:14,  
      title:"مقدمة عن الاستثمار",
        data:"6 أغسطس 2023" ,
        desc:"يعد الاستثمار من أهم القضايا التي تدور حولها الأبحاث الاقتصادية والعلمية , كما يفتح العديد من النقاشات , كما يفتح العديد من النقاشات "
    },{
      id:15,  
      title:"مقدمة عن الاستثمار",
        data:"6 أغسطس 2023" ,
        desc:"يعد الاستثمار من أهم القضايا التي تدور حولها الأبحاث الاقتصادية والعلمية , كما يفتح العديد من النقاشات , كما يفتح العديد من النقاشات "
    },{
      id:16,  
      title:"مقدمة عن الاستثمار",
        data:"6 أغسطس 2023" ,
        desc:"يعد الاستثمار من أهم القضايا التي تدور حولها الأبحاث الاقتصادية والعلمية , كما يفتح العديد من النقاشات , كما يفتح العديد من النقاشات "
    },{
      id:17,  
      title:"مقدمة عن الاستثمار",
        data:"6 أغسطس 2023" ,
        desc:"يعد الاستثمار من أهم القضايا التي تدور حولها الأبحاث الاقتصادية والعلمية , كما يفتح العديد من النقاشات , كما يفتح العديد من النقاشات "
    },{
      id:18,  
      title:"مقدمة عن الاستثمار",
        data:"6 أغسطس 2023" ,
        desc:"يعد الاستثمار من أهم القضايا التي تدور حولها الأبحاث الاقتصادية والعلمية , كما يفتح العديد من النقاشات , كما يفتح العديد من النقاشات "
    },{
      id:19,  
      title:"مقدمة عن الاستثمار",
        data:"6 أغسطس 2023" ,
        desc:"يعد الاستثمار من أهم القضايا التي تدور حولها الأبحاث الاقتصادية والعلمية , كما يفتح العديد من النقاشات , كما يفتح العديد من النقاشات "
    },{
      id:20,  
      title:"مقدمة عن الاستثمار",
        data:"6 أغسطس 2023" ,
        desc:"يعد الاستثمار من أهم القضايا التي تدور حولها الأبحاث الاقتصادية والعلمية , كما يفتح العديد من النقاشات , كما يفتح العديد من النقاشات "
    },{
      id:21,  
      title:"مقدمة عن الاستثمار",
        data:"6 أغسطس 2023" ,
        desc:"يعد الاستثمار من أهم القضايا التي تدور حولها الأبحاث الاقتصادية والعلمية , كما يفتح العديد من النقاشات , كما يفتح العديد من النقاشات "
    },{
      id:22,  
      title:"مقدمة عن الاستثمار",
        data:"6 أغسطس 2023" ,
        desc:"يعد الاستثمار من أهم القضايا التي تدور حولها الأبحاث الاقتصادية والعلمية , كما يفتح العديد من النقاشات , كما يفتح العديد من النقاشات "
    },
]


// ! Videos cards data
