import StockChart from './StockChart'
import VirtualInput from '../common/VirtualInput'
import { useNavigate } from 'react-router-dom'
import VirtualButton from '../common/VirtualButton'

const StockOpened = ({ item }) => {

    const actual_timeFrame_int = item?.actual_timeframe.map(item => Math.floor(item));
    const actual_prediction_int = item?.prediction.map(item => Math.floor(item));

    return (
        <div className='lg:h-96 bg-white rounded-xl p-3 shadow-lg flex flex-col lg:flex-row items-center gap-5 h-full'>
            <div className='w-full lg:flex-[1]'>
                <StockChart time_frame={actual_timeFrame_int} predicted_values={actual_prediction_int} date={item?.date} />
            </div>
            <StockData company_name={item?.company_name} item={item} />
        </div>
    )
}
const StockData = ({ company_name, item }) => {
    const navigate = useNavigate();

    // const closed_price = item?.close_price.Math.floor(item) || null;
    // const opened_price = item?.open_price.Math.floor(item) || null;

    return (
        <div className='flex lg:flex-col justify-around items-center h-full gap-5 flex-wrap'>

            <VirtualInput placeholder={"EGP"} label={"سعر الشراء"} value={item?.open_price} type={"number"} disabled={true} />
            <VirtualInput placeholder={"EGP"} label={"سعر البيع"} value={item?.close_price} type={"number"} disabled={true} />
            <VirtualButton text=" إشتري الأن" action={() => { navigate(`/virtual-wallet/buy-stocks/${company_name}`, { state: { item } }) }} className={"mt-8"} />

        </div>
    )
}

export default StockOpened