import React from 'react'
import VideoImage from "../../../../assets/Videos_1.png"
import Avatar from "../../../../assets/Avatar_Image.png"
const VideoCard = ({ item, i }) => {
    return (
        <div className='bg-white rounded-3xl px-4 lg:px-12 py-2 lg:py-6 cursor-pointer my-4' key={i}>
            <div className="flex flex-col md:flex-row md:items-center gap-x-8">

                <img src={item?.author_image} alt="VideoImage" className='rounded-3xl w-full h-full md:w-[200px] md:h-[200px]' />

                <div className='flex flex-col gap-y-4'>

                    <h4 className='text-xl lg:text-3xl font-bold mt-3 md:mt-0 '>{item?.title}</h4>
                    {/* <div className="flex items-center flex-wrap gap-x-4 gap-y-2 text-[#767B91] my-2">
                        <span className='text-[14px]'>
                            المشاهدات : 234 مشاهده
                        </span>
                        <span className='text-[14px]'>
                            الإعجاب : 234 إعجاب
                        </span>
                    </div> */}

                    <div className='flex items-center gap-x-1'>
                        {/* <img src={Avatar} alt="" className='rounded-full h-12 w-12' /> */}

                        <div>
                            <h5 className='py-1'>
                                {item?.author}
                            </h5>
                            <span className='text-[14px] text-[#767B91]'>{item?.date}</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default VideoCard