import React from 'react';
import UserProfileHeader from '../../components/socialCommunity/core/profile/UserProfileHeader';
import { useQuery } from 'react-query';

import { request } from '../../utils/axios';
import { useLocation, useParams } from 'react-router-dom';
import Post from '../../components/socialCommunity/core/post/Post';


const UserProfile = () => {
    const { userId } = useParams();
    // const { pathname } = useLocation();
    // console.log(userId);
    const fetchProfile = async () => {
        const response = await request({
            url: `/userProfile/2`
        });
        if (response.status === 200) {
            return response?.data?.data;
        }
        return [];
    };
    const fetchFollowers = async () => {
        const response = await request({
            url: `/userFollowers/${userId}`
        });
        if (response.status === 200) {
            return response?.data?.data;
        }
        return [];
    };

    const fetchFollowees = async () => {
        const response = await request({
            url: `/userFollowees/${userId}`
        });
        if (response.status === 200) {
            return response?.data?.data;
        }
        return [];
    };
    const fetchUserPosts = async () => {
        const response = await request({
            url: `/posts/userPosts/${userId}`
        });
        if (response.status === 200) {
            return response?.data?.data;
        }
        return [];
    };
    const { data: userPosts } = useQuery("userPosts", fetchUserPosts);
    const { data } = useQuery("userProfile", fetchProfile);
    const { data: followersData } = useQuery("followers", fetchFollowers);
    const { data: followeesData } = useQuery("followees", fetchFollowees);

    return (
        <div>
            <UserProfileHeader userData={data} followers={followersData} followees={followeesData} />

            <div className='flex flex-col items-center justify-center gap-5'>
                {userPosts?.map((post, i) => <Post key={i} data={post} />)}
            </div>

        </div>
    )
}

export default UserProfile