import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { VirtualWalletProvider } from "./actions/context/VirtualWalletContext";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import store from "./actions/redux/store";
import RecommendationProvider from "./actions/context/useRecommendationContext";


const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Toaster position="top-center" reverseOrder={false} />
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <RecommendationProvider>
                    <BrowserRouter>
                        <VirtualWalletProvider>
                            <App />
                        </VirtualWalletProvider>
                    </BrowserRouter>
                </RecommendationProvider>
            </Provider>
        </QueryClientProvider>
    </React.StrictMode>
);
