import React from 'react'

const VirtualInput = ({
    value, onChange, type, className, label, placeholder, disabled, name
}) => {
    return (
        <div className='relative w-fit'>
            <label htmlFor="" className='block text-mainColor mb-1 font-semibold px-1'>{label}</label>
            <input type={type} disabled={disabled ? true : false} value={value} name={name} onChange={onChange} className={` ${className} w-[200px] text-mainColor border border-mainColor rounded-lg py-1 px-2 bg-white`} />
            <span className='text-mainColor font-semibold text-end absolute  left-[4%] top-[55%]'>{placeholder}</span>
        </div>
    )
}

export default VirtualInput