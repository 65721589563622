import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const StockChart = ({ time_frame, predicted_values, date }) => {
  const [series, setSeries] = useState([
    {
      name: "قيم الأسهم الحالية",
      data: time_frame || []
    },
    {
      name: "قيم الأسهم المتوقعه",
      data: predicted_values || []
    }
  ]);

  useEffect(() => {
    setSeries([
      {
        name: "قيم الأسهم الحالية",
        data: time_frame || []
      },
      {
        name: "قيم الأسهم المتوقعه",
        data: predicted_values || []
      }
    ]);
  }, [time_frame, predicted_values]);

  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    // fill: {
    //   type: 'gradient' / 'solid' / 'pattern' / 'image'
    // },
    stroke: {
      curve: 'smooth',
      colors: ["#A37E2C", '#008FFB'],
      width: 2,
    },
    grid: {
      row: {
        colors: ['transparent'],
        opacity: 0.5
      },
    },
    xaxis: {
      categories: ["Mon", "Tus", "Wed", "Thu", "Fri", "Sat", "Sun"],
    },
    legend: {
      fontSize: '15px',
      margin: "4px",
      fontFamily: "Cairo, sans-serif"
    }
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={options} series={series} type="line" height={350} />
      </div>
    </div>
  );
};

export default StockChart;
