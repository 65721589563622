import React, { useState } from 'react'
import ContentWrapper from '../../../virtualWallet/common/ContentWrapper'
import PostHeader from './PostHeader'
import ContentPost from './ContentPost'
import ReactionBar from './ReactionBar'
import Comments from './Comments'

const Post = ({ data }) => {
    const [commentsOpened, setCommentsOpened] = useState(false);
    // console.log(data);
    return (
        <ContentWrapper className={"p-2 my-3 duration-300"}>
            <PostHeader avatar={data?.user_image} name={data?.user_name} created_at={data?.created_at} userId={data?.Post_owner_id}/>
            <ContentPost text={data?.content} postImage={data?.image} />
            <ReactionBar total_likes={data?.total_likes} total_comments={data?.total_comments} setCommentsOpened={setCommentsOpened} id={data?.post_id} />
            <Comments commentsOpened={commentsOpened} userComments={data?.comments} id={data?.post_id} avatar={data?.user_image} />
        </ContentWrapper>
    )
}

export default Post