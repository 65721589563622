import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { AnimatePresence, motion } from "framer-motion";
import { findInputError, isFormInvalid } from "../../utils/auth";
import { MdError } from "react-icons/md";

const AuthInput = ({
  Icon,
  id,
  type,
  placeholder,
  validation,
  name,
  value,
  onChange,
  width,
  label,
}) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  useEffect(() => {
    setValue(name, value);
  }, [setValue, name, value]);
  const inputError = findInputError(errors, name);
  const isInvalid = isFormInvalid(inputError);
  return (
    <div className={`${width}`}>
      <div className="relative flex-1">
        <label className="flex flex-col items-start">
          {label && label}
          <input
            id={id}
            placeholder={placeholder && placeholder}
            type={type}
            className={` ${width}  border border-black rounded-lg outline-none ${Icon ? " py-3" : "py-2"} ${Icon ? " px-9" : "px-2"} my-2`}
            onChange={onChange}
            {...register(`${name}`, validation)}
          />
        </label>
        {Icon ? (
          <>
            <Icon
              className={`absolute top-1/2  right-0 transform translate-x-[-50%] translate-y-[-50%] text-2xl text-[#5A6067]`}
            ></Icon>
          </>
        ) : null}
      </div>
      <AnimatePresence mode="wait" initial={false}>
        {isInvalid && (
          <InputError
            message={inputError.error.message}
            key={inputError.error.message}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

const InputError = ({ message }) => {
  return (
    <motion.p
      className="w-fit flex items-center gap-1 px-2 font-semibold text-red-500 bg-red-100 rounded-md"
      {...framer_error}
    >
      <MdError />
      {message}
    </motion.p>
  );
};

const framer_error = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 },
  transition: { duration: 0.2 },
};

export default AuthInput;
