import React from "react";
import PieChart from "../components/charts/PieChart";
import CompanyShares from "../components/charts/CompanyShares";
import CompanyCharts from "../components/charts/CompanyCharts";
import MonthlyChart from "../components/charts/MonthlyChart";
import { useRecommendation } from "../context/useRecommendationContext";

const Charts = () => {
  const { response } = useRecommendation();
  const storedRecommendation = localStorage.getItem('recommendation');

  console.log(storedRecommendation);
  return (
    <div className="mt-20">
      {/* <PieChart data={response} /> */}
      <CompanyShares response={response} />
      {/* <CompanyCharts /> */}
      {/* <MonthlyChart /> */}
    </div>
  );
};

export default Charts;
