import React from "react";

const AuthBtn = ({ action, text, width }) => {
  return (
    <button
      onClick={action}
      className={`${width} text-white bg-mainColor  rounded-lg outline-none py-3 px-9 my-2 hover:bg-[#94783f] transition-all`}
    >
      {text}
    </button>
  );
};

export default AuthBtn;
