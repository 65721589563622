import axios from "axios";

const lang = localStorage.getItem("lang")
  ? JSON.parse(localStorage.getItem("lang"))
  : "ar";

const authToken = localStorage.getItem("tokenInvesto")
  ? JSON.parse(localStorage.getItem("tokenInvesto"))
  : null;

const client = axios.create({
  baseURL: "https://api.tslplatform.com/api",
  headers: {
    "Content-Type": "application/json",
    lang,
    "Accept-Language": lang,
    "Access-Control-Allow-Credentials": true,
    "x-api-key": "0FcBOe64FIFkBkNkA",
    Authorization: authToken ? `Bearer ${authToken}` : null,
  },
});

// Request interceptor
client.interceptors.request.use(
  (config) => {
    config.headers.lang = localStorage.getItem("lang")
      ? JSON.parse(localStorage.getItem("lang"))
      : "ar";
    config.headers["Accept-Language"] = localStorage.getItem("lang")
      ? JSON.parse(localStorage.getItem("lang"))
      : "ar";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
client.interceptors.response.use(
  (response) => {
    // Modify response data before resolving
    return response;
  },
  (error) => {
    // Enhanced error handling
    if (error.response) {
      console.error("Error response data:", error.response.data);
      console.error("Error response status:", error.response.status);
      console.error("Error response headers:", error.response.headers);
    } else {
      console.error("Error message:", error.message);
    }
    return Promise.reject(error);
  }
);
export const request = ({ ...options }) => {
  const onSuccess = (response) => {
    return response;
  };

  const onError = (error) => {
    return error;
  };

  return client(options).then(onSuccess).catch(onError);
};
