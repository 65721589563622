import React, { useEffect, useState } from "react";

const DatePicker = ({ onDateChange, setDate, date }) => {
    const currentYear = new Date().getFullYear();

    const handleDateChange = (e) => {
        let { value } = e.target;
        setDate((prev) => ({
            ...prev,
            selectedDate: value
        }))

    };

    const handleMonthChange = (e) => {
        const value = e.target.value;
        setDate((prev) => ({
            ...prev,
            selectedMonth: value
        }));
    };

    const handleYearChange = (e) => {
        const value = e.target.value;
        setDate((prev) => ({
            ...prev,
            selectedYear: value
        }));
    };

    useEffect(() => {
        const { selectedDate, selectedMonth, selectedYear } = date;
        if (selectedDate && selectedMonth && selectedYear) {
            const formattedDate = new Date(selectedYear, selectedMonth - 1, selectedDate);
            onDateChange(formattedDate.toISOString().slice(0, 10));
        }
    }, [date, onDateChange]);

    const renderDateOptions = () => {
        const dateOptions = [
            <option key={0} value="" disabled>
                اليوم
            </option>
        ];
        for (let i = 1; i <= 31; i++) {
            dateOptions.push(
                <option key={i} value={i}>
                    {i}
                </option>
            );
        }
        return dateOptions;
    };



    const renderMonthOptions = () => {
        const monthOptions = [
            <option key={0} value={""} disabled>
                الشهر
            </option>,
        ];

        for (let i = 1; i <= 12; i++) {
            monthOptions.push(
                <option key={i} value={i}>
                    {new Date(2000, i - 1, 1).toLocaleString("default", {
                        month: "long",
                    })}
                </option>
            );
        }

        return monthOptions;
    };

    const renderYearOptions = () => {
        const yearOptions = [
            <option key={0} value={""} disabled>
                السنه
            </option>,
        ];

        for (let i = currentYear; i >= 1900; i--) {
            yearOptions.push(
                <option key={i} value={i}>
                    {i}
                </option>
            );
        }

        return yearOptions;
    };
    return (
        <div>
            <p className="text-start">تاريخ الميلاد</p>
            <div className="app ">
                <div className="app-container flex  items-center gap-x-1 md:gap-x-2 gap-y-2 ">
                    <div className="app-select flex-1 w-full">
                        <select
                            className="w-full px-2 border p-2 rounded-xl border-black cursor-pointer"
                            value={date.selectedDate}
                            onChange={handleDateChange}
                        >
                            {renderDateOptions()}
                        </select>
                    </div>
                    <div className="app-select flex-1 w-full">
                        <select
                            className="w-full px-2 border p-2 rounded-xl border-black cursor-pointer"
                            value={date.selectedMonth}
                            onChange={handleMonthChange}
                        >
                            {renderMonthOptions()}
                        </select>
                    </div>
                    <div className="app-select flex-1 w-full">
                        <select
                            className="w-full px-2 border p-2 rounded-xl border-black cursor-pointer"
                            value={date.selectedYear}
                            onChange={handleYearChange}
                        >
                            {renderYearOptions()}
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DatePicker;
