import React from "react";
import Navbar from "./navbar/Navbar";
import Sidebar from "./Sidebar";
import { Outlet } from "react-router-dom";

const Root = () => {
    return (
        <div>
            <Navbar />
            <Sidebar />
            <div className="pl-4 pr-20 md:px-20 mt-20">
                <Outlet />
            </div>
        </div>
    );
};

export default Root;
