import React from 'react'
import HeaderProfile from "../../components/socialCommunity/core/profile/HeaderProfile";
import CommunitySidebar from "../../components/socialCommunity/core/CommunitySidebar";
import { request } from '../../utils/axios';
import { useQuery } from 'react-query';
import Post from '../../components/socialCommunity/core/post/Post';

const storedValue = localStorage.getItem('userInvesto');
const parsedValue = JSON.parse(storedValue);
const fetchUserProfile = async () => {
    const response = await request({
        url: `/profile`
    });
    if (response.status === 200) {
        return response?.data?.data;
    }
    return [];
};
const fetchFollowers = async () => {
    const response = await request({
        url: `/userFollowers/${parsedValue.id}`
    });
    if (response.status === 200) {
        return response?.data?.data;
    }
    return [];
};

const fetchFollowees = async () => {
    const response = await request({
        url: `/userFollowees/${parsedValue.id}`
    });
    if (response.status === 200) {
        return response?.data?.data;
    }
    return [];
};
const fetchMyPosts = async () => {
    const response = await request({
        url: `/posts`
    });
    if (response.status === 200) {
        return response?.data?.data;
    }
    return [];
};
const MyProfile = () => {
    const { data } = useQuery("myProfile", fetchUserProfile);
    const { data: followersData } = useQuery("followers", fetchFollowers);
    const { data: followeesData } = useQuery("followees", fetchFollowees);
    const { data: myPosts } = useQuery("myPosts", fetchMyPosts);

    return (
        <CommunitySidebar>

            <div>
                <HeaderProfile userData={data} followers={followersData} followees={followeesData} />
                <div className='flex flex-col items-center justify-center gap-5'>
                    {myPosts?.map((post, i) => <Post key={i} data={post} />)}
                </div>
            </div>
        </CommunitySidebar>
    )
};

export default MyProfile