import React, { useEffect, useState } from "react";
import EducationalSidebar from "../../components/educationalPlatForm/EducationalSidebar";
import CustomSelect from "../../components/educationalPlatForm/courses/CustomSelect";
import { languagesCourse, levels } from "../../data/data";
import { FaFilter } from "react-icons/fa";
import CourseCard from "../../components/educationalPlatForm/courses/CourseCard";
import AOS from "aos";
import { useQuery } from "react-query";
import { requestEdu } from "../../utils/axiosEdu";


const fetchCourses = async () => {
    const response = await requestEdu({
        url: "/courses/",
    });
    if (response.status === 200) {
        return response.data; 
    }
    return [];
};

const Courses = () => {
    const { data, isLoading, isError } = useQuery("courses", fetchCourses);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error loading data</div>;
    }

    return (
        <EducationalSidebar>
            <FiltrationHeader />
            <CoursesData data={data} />
        </EducationalSidebar>
    );
};

/* header => filtration */
const FiltrationHeader = () => {
    const [level, setLevel] = useState();
    const [language, setLanguage] = useState();

    const handleLevelSelect = (value) => {
        setLevel(value);
    };

    const handleLanguageSelect = (value) => {
        setLanguage(value);
    };

    return (
        <div className="flex items-end justify-between gap-3 flex-wrap">
            <div className="flex items-center flex-wrap gap-3">
                <CustomSelect
                    width="w-44"
                    label="المستوي"
                    options={levels}
                    onSelect={handleLevelSelect}
                    value={level ? level : "أختر المستوي"}
                />

                <CustomSelect
                    width="w-44"
                    label="لغة الدورة"
                    options={languagesCourse}
                    onSelect={handleLanguageSelect}
                    value={language ? language.name : "أختر اللغه"}
                />
            </div>

            <div className="flex justify-end">
                <button className="flex items-center gap-x-2 text-white bg-mainColor px-4 py-[3px] rounded-lg">
                    <FaFilter />
                    تصفية
                </button>
            </div>
        </div>
    );
};

/* courses cards*/
const CoursesData = ({ data }) => {
    const [showMore, setShowMore] = useState(false);
    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    // ! handle animation
    useEffect(() => {
        AOS.init({
            duration: 300,
        });
    }, []);
    const animationDelayFactor = 200;

    return (
        <>
            <div className="mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-8 gap-x-8  xl:gap-x-16 ">
                {data
                    ?.slice(0, showMore ? data.data.length : 9)
                    ?.map((item, i) => (
                        <CourseCard item={item} key={i} animationDelayFactor={animationDelayFactor} index={i} />
                    ))}
            </div>

            {/* <div className="flex items-center justify-center my-12">
                <button className="text-center font-bold border border-mainColor rounded-full text-mainColor py-1 px-2 hover:bg-mainColor hover:text-white transition-all"
                    onClick={toggleShowMore}>

                    {showMore ? "شاهد أقل" : "شاهد المزيد"}

                </button> */}
            {/* </div> */}
        </>
    )
}
export default Courses;
