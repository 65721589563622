import React from 'react';
import GreenChart from "../../../../assets/greenChart.svg";
import RedChart from "../../../../assets/redChart.svg";

const StockClosed = ({ item, action }) => {
    return (
        <div className='my-6'>
            <div className="w-full p-4 rounded-xl shadow-xl bg-white my-4 cursor-pointer" onClick={action}>
                <div className="flex items-center justify-between">
                    <h5 className='font-semibold text-sm'>{item?.company_name}</h5>
                    <img src={item?.id % 2 ? GreenChart : RedChart} alt={item?.company_name} className='w-[80px] md:w-fit' />
                    <div className='flex-col items-center gap-2 text-sm md:text-base'>
                        <p className='text-[#999999] flex items-center gap-1 text-sm'>
                            <span className='text-mainColor'>سعر الشراء : </span>
                            {item?.open_price}
                        </p>
                        <p className='text-[#999999] flex items-center gap-1 text-sm'>
                            <span className='text-mainColor'>سعر البيع : </span>
                            {item?.close_price}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StockClosed;
