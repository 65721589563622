import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { MdOutlineCancel } from "react-icons/md";
import { useOutSideClick } from "../../hooks/useOutSideClick";
import ForgetPassForm from "./ForgetPassForm";
import CheckMail from "./CheckMail";
import ResetPassword from "./ResetPassword";
const ForgetPassModel = ({ setOpenForgetPass }) => {
  useEffect(() => {
    AOS.init({
      duration: 500,
      once: true,
    });
  }, []);

  // ======= handle close popup =======
  const handleCloseForgetPass = () => {
    setOpenForgetPass(false);
  };
  // ======= Outside click handler =======
  const ref = useOutSideClick(handleCloseForgetPass);
  // ======= Prevent click events from propagating to the parent element =======
  const handleHeaderClick = (event) => {
    event.stopPropagation();
  };
  return (
    <div className="z-[1000] fixed top-0 left-0 w-full h-full text-center flex items-center justify-center bg-black bg-opacity-50">
      <div
        ref={ref}
        data-aos="zoom-in"
        data-aos-once="true"
        className="w-[300px] sm:w-[345px] z-[1000] relative   rounded-xl bg-white"
        onClick={handleHeaderClick}
      >
        <div className="px-1 py-2 rounded-t-xl bg-mainColor text-white flex items-center">
          <MdOutlineCancel
            size={22}
            onClick={() => handleCloseForgetPass()}
            className="cursor-pointer"
          />

          <p className="pr-[105px]">نسيت كلمة المرور</p>
        </div>
        <div className=" px-6 py-3">
          {/* <ForgetPassForm /> */}
          {/* <CheckMail /> */}
          <ResetPassword/>
        </div>
      </div>
    </div>
  );
};


export default ForgetPassModel;
