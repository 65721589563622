import React from 'react'
import FeaturesImage from "../../../assets/features.svg"
const OurFeatures = () => {
  return (
    <div className='flex items-center justify-center flex-col gap-y-8'>
        <Title/>
        <img src={FeaturesImage} alt='Features_image' className='w-fit'/>
    </div>
  )
}

const Title = () =>{
    return (
        <div className='text-center'>
            <h1 className='text-2xl md:text-3xl lg:text-4xl font-bold text-mainColor py-4'>ما الذى تقدمة لك منصة إنفيستوا ؟</h1>
            <p className='text-black opacity-65'>هنالك الكثير ممّا يمكنك القيام به على موقعنا!</p>
        </div>
    )
}

export default OurFeatures