import React, { useState } from 'react'
import StockClosed from './StockClosed'
import StockOpened from './StockOpened';


// const data = [
//     {
//         id: 1,
//         company_name: "appel",
//         close_price: 1800,
//         open_close: 1600,
//         time_frame: [123, 134, 155, 145, 120, 123, 150],
//         predicted_values: [126, 130, 145, 140, 125, 127, 140],
//         stoke_value: 1700
//     },
//     {
//         id: 2,
//         company_name: "appel",
//         close_price: 1800,
//         open_close: 1600,
//         time_frame: [123, 134, 155, 145, 120, 123, 150],
//         predicted_values: [126, 130, 145, 140, 125, 127, 140],
//         stoke_value: 1700
//     },
//     {
//         id: 3,
//         company_name: "appel",
//         close_price: 1800,
//         open_close: 1600,
//         time_frame: [123, 134, 155, 145, 120, 123, 150],
//         predicted_values: [126, 130, 145, 140, 125, 127, 140],
//         stoke_value: 1700
//     },
//     {
//         id: 4,
//         company_name: "appel",
//         close_price: 1800,
//         open_close: 1600,
//         time_frame: [123, 134, 155, 145, 120, 123, 150],
//         predicted_values: [126, 130, 145, 140, 125, 127, 140],
//         stoke_value: 1700
//     },
//     {
//         id: 5,
//         company_name: "appel",
//         close_price: 1800,
//         open_close: 1600,
//         time_frame: [123, 134, 155, 145, 120, 123, 150],
//         predicted_values: [126, 130, 145, 140, 125, 127, 140],
//         stoke_value: 1700
//     },
//     {
//         id: 6,
//         company_name: "appel",
//         close_price: 1800,
//         open_close: 1600,
//         time_frame: [123, 134, 155, 145, 120, 123, 150],
//         predicted_values: [126, 130, 145, 140, 125, 127, 140],
//         stoke_value: 1700
//     },
//     {
//         id: 7,
//         company_name: "appel",
//         close_price: 1800,
//         open_close: 1600,
//         time_frame: [123, 134, 155, 145, 120, 123, 150],
//         predicted_values: [126, 130, 145, 140, 125, 127, 140],
//         stoke_value: 1700
//     },
//     {
//         id: 8,
//         company_name: "appel",
//         close_price: 1800,
//         open_close: 1600,
//         time_frame: [123, 134, 155, 145, 120, 123, 150],
//         predicted_values: [126, 130, 145, 140, 125, 127, 140],
//         stoke_value: 1700
//     }
// ]
const StockContainer = ({ data }) => {
    const [openItemId, setOpenItemId] = useState(null);

    const handleToggle = (id) => {
        setOpenItemId(openItemId === id ? null : id);
    };
    return (
        <div className='mt-6'>
            {data?.map(item => (
                <div key={item?.id}>
                    {openItemId === item?.id ? (
                        <StockOpened item={item} />
                    ) : (
                        <StockClosed item={item} action={() => handleToggle(item?.id)} isOpen={openItemId === item?.id} />
                    )}
                </div>
            ))}
        </div>
    )
}

export default StockContainer