import axios from 'axios';

const lang = localStorage.getItem("lang")
    ? JSON.parse(localStorage.getItem("lang"))
    : "ar";

const authToken = localStorage.getItem("tokenInvesto")
    ? JSON.parse(localStorage.getItem("tokenInvesto"))
    : null;

const client = axios.create({
    baseURL: "https://gaafar.pythonanywhere.com/api",
    headers: {
        "Content-Type": "application/json",
        lang,
        "Accept-Language": lang,
        "Access-Control-Allow-Credentials": true,
        "x-api-key": "0FcBOe64FIFkBkNkA",
        Authorization: authToken ? `Bearer ${authToken}` : null,
    },
});

// Request interceptor
client.interceptors.request.use(
    (config) => {
        config.headers.lang = localStorage.getItem("lang")
            ? JSON.parse(localStorage.getItem("lang"))
            : "ar";
        config.headers["Accept-Language"] = localStorage.getItem("lang")
            ? JSON.parse(localStorage.getItem("lang"))
            : "ar";
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor
client.interceptors.response.use(
    (response) => {
        // Modify response data before resolving
        return response;
    },
    (error) => {
        // Handle response error
        return Promise.reject(error);
    }
);

export const requestEdu = ({ ...options }) => {
    const onSuccess = (response) => {
        return response;
    };

    const onError = (error) => {
        return error;
    };

    return client(options).then(onSuccess).catch(onError);
};
