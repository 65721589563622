import React from 'react'
import { PiGraduationCap } from "react-icons/pi";
const CourseCard = ({ item, animationDelayFactor, index }) => {

    return (
        <div className='flex flex-col bg-white rounded-[30px]'
            data-aos="fade-up"
            data-aos-delay={index * animationDelayFactor}
        >
            <div className='relative'>

                <img src={item.image} alt="course" className='w-full rounded-[30px]  border-[10px] border-mainColor h-[240px]' />
                {item.accredited === true ?
                    <span className='text-white bg-mainColor rounded-l-[30px] py-1 px-2 absolute right-1 top-[60%] w-fit'>
                        معتمد بشهادة
                    </span>
                    : null}

            </div>

            <div className=' p-6'>
                <p className="text-[16px] lg:text-[18px] font-semibold">
                    {item.title}
                </p>

                <div className="flex justify-between items-center py-6">
                    <div className='flex items-center gap-x-3'>
                        {/* <img src={item.avatar} alt="avatar" className='w-10 h-10 rounded-full' /> */}
                        <p className="text-[14px] md:text-base "> {item.level}</p>
                    </div>

                    {/* <span className="text-[14px] md:text-base  flex items-center gap-x-1 text-mainColor bg-[#ECECEC] py-1 px-[6px] rounded-full font-semibold">
                        <PiGraduationCap />
                        {item.price}
                    </span> */}
                </div>

                <div className="flex items-center justify-between">
                    <button className='bg-mainColor text-white rounded-full py-2 px-6'>شارك الدوره</button>
                    {/* <span className="text-[14px] md:text-base font-semibold">
                        {item.price}
                    </span> */}
                </div>

            </div>
        </div>
    )
}

export default CourseCard