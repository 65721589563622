import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import VirtualButton from '../../components/virtualWallet/common/VirtualButton';
import ContentWrapper from '../../components/virtualWallet/common/ContentWrapper';
import StockChart from "../../components/virtualWallet/charts/StockChart";
import { request } from '../../utils/axios';
import { useQuery } from 'react-query';
import VirtualInput from '../../components/virtualWallet/common/VirtualInput';
import { GoArrowSwitch } from 'react-icons/go';
import toast from 'react-hot-toast';
// const fetch budget 
const fetchBudget = async () => {
    const response = await request({
        url: `/userBudget`
    });
    if (response.status === 200) {
        return response?.data;
    }
    return [];
};
const BuyStocks = () => {
    const { company_name } = useParams();

    const fetchSingleCompany = async () => {
        const response = await request({
            url: `/company-data/${company_name}`
        });
        if (response.status === 200) {
            return response.data;
        }
        return [];
    };

    const { data, isLoading } = useQuery("singleCompany", fetchSingleCompany);
    const { data: userBudget, isLoading: loading } = useQuery("userBudget", fetchBudget);

    if (isLoading && loading) {
        return <div>Loading...</div>;
    }

    if (!data) {
        return <div>No data available</div>;
    }

    const actual_timeframe_int = data?.actual_timeframe?.map(item => Math.floor(item));
    const actual_prediction_int = data?.prediction?.map(item => Math.floor(item));

    return (
        <div>
            <div className="flex gap-x-2 xl:gap-x-8 gap-y-2 flex-col lg:flex-row justify-center">
                <div className="flex gap-y-2 gap-x-2 flex-col md:flex-row justify-center flex-[.6]">
                    <ContentWrapper className={"flex flex-col gap-2 items-center justify-center flex-1 lg:flex-[.9]"}>
                        <div className='flex items-center gap-3 text-lg'>
                            <span className='text-white bg-mainColor p-1 rounded-lg'>EGP</span>
                            <p className='text-mainColor font-bold'>المبلغ المتاح</p>
                        </div>
                        <h5 className='font-bold text-2xl text-nowrap text-mainColor'><bdi>{userBudget?.budget} EGP</bdi></h5>
                    </ContentWrapper>
                    <ButtonContainer />
                </div>
                <InvestmentData data={data} />
            </div>
            <ContentWrapper className={"mt-6"}>
                <h5 className='font-bold text-base md:text-lg mb-3 '>{data?.company_name}</h5>
                <StockChart time_frame={actual_timeframe_int} predicted_values={actual_prediction_int} />
            </ContentWrapper>
        </div>
    );
}

const ButtonContainer = () => {
    const navigate = useNavigate();

    return (
        <div className='flex flex-col sm:flex-row md:flex-col items-center justify-around gap-3 flex-1'>
            <VirtualButton text="سجل إستثماري" action={() => { navigate("/virtual-wallet/investment-report") }} />
            <VirtualButton text="المحفظة الوهمية" action={() => { navigate("/virtual-wallet") }} />
        </div>
    );
}
const InvestmentData = ({ data }) => {
    console.log(data?.company_name);
    const [stockData, setStockData] = useState({
        company_name: data?.company_name,
        number_of_stocks: null
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setStockData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        console.log(stockData);
    }, [stockData]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("company_name", stockData.company_name);
        formData.append("number_of_stocks", stockData.number_of_stocks);

        try {
            const response = await request({
                url: "/buy",
                method: "post",
                data: formData
            });

            if (response.status === 200) {
                toast.success("تم شراء السهم بنجاح");
            } else {
                toast.error("يوجد خطأ ما");
            }
        } catch (error) {
            toast.error("يوجد خطأ ما");
        }
    };

    return (
        <ContentWrapper className="flex-1 flex items-center justify-center">
            <form onSubmit={handleSubmit} className='flex flex-col items-center justify-center flex-wrap gap-4 w-full'>
                <h5 className='text-mainColor text-xl font-bold py-3'>أدخل عدد الأسهم</h5>
                <div className="flex items-center justify-center flex-wrap gap-x-4">
                    <VirtualInput
                        placeholder={"EGP"}
                        label={"سعر الشراء"}
                        value={data?.open_price}
                        type={"number"}
                        disabled={true}
                    />
                    <VirtualInput
                        placeholder={"%"}
                        label={"سعر البيع"}
                        value={data?.close_price}
                        type={"number"}
                        disabled={true}
                    />
                    <GoArrowSwitch className='mt-8 text-mainColor text-xl hidden md:block' />
                    <VirtualInput
                        placeholder={"سهم"}
                        label={"عدد الاسهم/ السعر"}
                        value={stockData.number_of_stocks}
                        type={"number"}
                        onChange={handleChange}
                        name={"number_of_stocks"}
                    />
                </div>
                <VirtualButton text={"شراء"} />
            </form>
        </ContentWrapper>
    );
};


export default BuyStocks;
