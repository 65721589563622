import React, { useState } from 'react';
import CommunityInput from '../CommunityInput';
import { IoIosSend } from "react-icons/io";
import { request } from '../../../../utils/axios';

const Comments = ({ commentsOpened, userComments, id, avatar }) => {
    const [isFocus, setFocus] = useState(false);
    const [comment, setComment] = useState("");
    const [commentsList, setCommentsList] = useState(userComments);

    const handleChange = (e) => {
        setComment(e.target.value);
    };

    const storedValue = localStorage.getItem('userInvesto');
    const parsedValue = JSON.parse(storedValue);

    const handleAddComment = async (e) => {
        e.preventDefault();

        if (comment.trim() !== "") {
            const formData = new FormData();
            formData.append("content", comment);
            formData.append("post_id", id);
            formData.append("user_id", parsedValue.id);

            const newComment = {
                id: commentsList?.length + 1,
                name: parsedValue.name,
                time: 'الآن',
                text: comment,
                likes: 0,
                isLiked: false,
            };

            try {
                const response = await request({
                    url: "/comments",
                    method: "post",
                    data: formData
                });

                if (response.status === 200) {
                    setCommentsList([...commentsList, newComment]);
                    setComment("");
                    setFocus(false);
                } else {
                    console.error("Failed to add comment");
                }
            } catch (error) {
                console.error("Error adding comment:", error);
            }
        }
    };

    return (
        <div className='mt-3 duration-300'>
            {commentsOpened && commentsList.map(comment => (
                <div key={comment.id} className="flex items-center justify-between gap-2 my-3">
                    <div className='flex items-center gap-[6px]'>
                        {/* { *
                            // avatar === null ? (/
                            // ) : (
                            //     <img src={comment?.user_image} alt="" className='w-[35px] h-[35px] md:w-[50px] md:h-[50px] rounded-full object-cover' />
                            // )
                        // }*} */}

                        <div className="relative w-8 h-8 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600 flex items-center justify-center">
                            <svg className="absolute w-8 h-8 text-gray-400 -left-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"></path>
                            </svg>
                        </div>

                        <div>
                            <p className='flex items-center gap-2 font-semibold'>{comment?.user_name} <span className='text-[#9B9B9B] text-xs font-normal'>
                                {comment?.created_at}
                            </span>
                            </p>
                            <span className='text-[#9B9B9B] text-sm'>{comment?.content}</span>
                        </div>
                    </div>
                </div>
            ))}
            <form onSubmit={handleAddComment}>
                <div className={`relative flex ${isFocus ? "items-start" : "items-center"} gap-x-3 md:gap-x-5`}>

                    {/* {
                        avatar === null ? (
                        ) : (
                            <img src={avatar} alt="" className='w-[35px] h-[35px] md:w-[50px] md:h-[50px] rounded-full object-cover' />
                        )
                        } */}

                    <div className="relative w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                        <svg className="absolute w-12 h-12 text-gray-400 -left-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"></path></svg>
                    </div>
                    <CommunityInput
                        placeholder={"اكتب تعليقك........."}
                        value={comment}
                        isFocus={isFocus}
                        setFocus={setFocus}
                        action={handleChange}
                        name={"content"}
                    />

                    <div className={` absolute transform translate-x-[-50%] translate-y-[-50%] left-[20px] ${isFocus ? " top-[70%]" : " top-[50%]"} flex justify-end  ${isFocus ? "mt-3" : "mt-0"} `}>
                        <IoIosSend onClick={handleAddComment} className='text-center bg-mainColor text-white hover:bg-white border-2
                            hover:border-mainColor hover:text-mainColor  duration-700 rounded-full w-[35px] h-[35px] p-1  transition-opacity text-[34px]'/>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Comments;
