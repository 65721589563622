import React from 'react'
import BgProfile from "../../../../assets/bg_profile.png";
import Avatar from "../../../../assets/Avatar_Image.png";
import { FaCamera } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { request } from '../../../utils/axios';

const storedValue = localStorage.getItem('userInvesto');
const parsedValue = JSON.parse(storedValue);
const fetchUserProfile = async () => {
    const response = await request({
        url: `/profile/${parsedValue.id}`
    });
    if (response.status === 200) {
        return response?.data?.data;
    }
    return [];
};
const fetchFollowers = async () => {
    const response = await request({
        url: `/userFollowers/${parsedValue.id}`
    });
    if (response.status === 200) {
        return response?.data?.data;
    }
    return [];
};

const fetchFollowees = async () => {
    const response = await request({
        url: `/userFollowees/${parsedValue.id}`
    });
    if (response.status === 200) {
        return response?.data?.data;
    }
    return [];
};
const ProfileCard = () => {
    const { data } = useQuery("userProfile", fetchUserProfile);
    const { data: followersData } = useQuery("followers", fetchFollowers);
    const { data: followeesData } = useQuery("followees", fetchFollowees);
    return (
        <div className='bg-white rounded-2xl shadow-xl'>
            <img src={BgProfile} alt="bg" className='w-full rounded-t-2xl' />
            <div className="relative ">
                {
                    data?.Profile_photo === null ? (
                        <img src={Avatar} alt="avatar" className='absolute transform translate-x-[-50%] translate-y-[-50%] top-0 left-[50%] w-[60px] h-[60px] rounded-full' />
                    ) : (
                        <img src={data?.Profile_photo} alt="" className='absolute transform translate-x-[-50%] translate-y-[-50%] top-0 left-[50%] w-[60px] h-[60px] rounded-full' />
                    )
                }

                {/* <FaCamera className='bg-white p-1 text-black rounded-full w-[30px] h-[30px] shadow-md transform translate-x-[-50%] translate-y-[-50%] top-0 right-0 cursor-pointer' /> */}
            </div>

            <div className="flex items-center justify-center flex-col gap-2 py-4 border-b border-[#d8d8d8] text-center px-1 lg:px-3">
                <h5 className='text-base md:text-xl lg:text-2xl  font-semibold mt-5'>{data?.First_name} {data?.Last_name}</h5>
                <p className='text-base  font-semibold'> ركز على نفسك عشان يبقى التركيز عليك </p>
            </div>

            <div className='flex items-center justify-around border-b border-[#d8d8d8]'>
                <div className='flex items-center justify-center flex-col '>
                    <p className='text-sm lg:text-base font-semibold'>{followersData?.length}</p>
                    <span className='text-[#909090] text-sm lg:text-base'>متابع</span>
                </div>
                <span className='w-[1px] h-[90px] bg-[#d8d8d8]'></span>
                <div className='flex items-center justify-center flex-col '>
                    <p className='text-sm lg:text-base font-semibold'>{followeesData?.length}</p>
                    <span className='text-[#909090] text-sm lg:text-base'>متابع</span>
                </div>
            </div>

            <div className='p-5 flex items-center justify-center'>
                <Link className='text-[#4D97FF] text-base md:text-lg lg:text-xl font-semibold'> الحساب</Link>
            </div>
        </div>
    )
}

export default ProfileCard