import React from "react";
import Hero from "../components/recommendation/Hero";
import MissionVision from "../components/recommendation/MissionVision";
import OurFeatures from "../components/recommendation/OurFeatures";
import ContactUsForm from "../components/recommendation/ContactUsForm";

const Recommendation = () => {
    return (
        <>
            <Hero />
            <MissionVision />
            <OurFeatures />
            <ContactUsForm />
        </>
    );
};

export default Recommendation;
