import { useState } from "react";
import { FaRegHeart, FaHeart, FaCommentDots } from "react-icons/fa6";
import { request } from "../../../../utils/axios";

const ReactionBar = ({ setCommentsOpened, total_likes, total_comments, id }) => {
    const [isLiked, setIsLiked] = useState(false);
    const [likesCount, setLikesCount] = useState(total_likes);

    const handleLikedToggle = async () => {
        const newLikeStatus = !isLiked;
        setIsLiked(newLikeStatus);
        setLikesCount(prev => newLikeStatus ? prev + 1 : prev - 1);

        try {
            const response = await request({
                url: `/posts/likeToggle/${id}`,
                method: "post",
                data: { liked: newLikeStatus } // Send the new like status to the backend
            });

            if (response.status !== 200) {
                // If the request fails, revert the state changes
                setIsLiked(!newLikeStatus);
                setLikesCount(prev => newLikeStatus ? prev - 1 : prev + 1);
                console.error("Failed to toggle like");
            }
        } catch (error) {
            // Handle the error appropriately
            setIsLiked(!newLikeStatus);
            setLikesCount(prev => newLikeStatus ? prev - 1 : prev + 1);
            console.error("Error toggling like:", error);
        }
    };

    return (
        <div className="flex items-center justify-around duration-300">
            {/*=================== likes part=================== */}
            <div
                className='flex items-center justify-center gap-1 px-4 py-[2px] border border-[#ECECEC] rounded-lg w-fit cursor-pointer mr-[70px] mt-1'
                onClick={handleLikedToggle}
            >
                {isLiked ? (
                    <FaHeart className='w-[15px] h-[15px] text-[#F44D6B]' />
                ) : (
                    <FaRegHeart className='w-[15px] h-[15px] text-[#9B9B9B]' />
                )}
                <span className='text-[#9B9B9B] cursor-pointer'>{likesCount} إعجاب</span>
            </div>

            {/*=================== comments part=================== */}
            <div
                className='flex items-center justify-center gap-1 px-4 py-[2px] border border-[#ECECEC] rounded-lg w-fit cursor-pointer mr-[70px] mt-1'
                onClick={() => setCommentsOpened(prev => !prev)}
            >
                <FaCommentDots className='w-[15px] h-[15px] text-[#9B9B9B]' />
                <span className='text-[#9B9B9B] cursor-pointer'>{total_comments} تعليق</span>
            </div>
        </div>
    );
};

export default ReactionBar;
