import React from 'react'
import Avatar from "../../../assets/Avatar Image.png";
import { FaCamera } from "react-icons/fa";
import SettingsInput from '../../components/settings/SettingsInput';
import { request } from '../../utils/axios';
import { useQuery } from 'react-query';


const storedValue = localStorage.getItem('userInvesto');
const parsedValue = JSON.parse(storedValue);
const fetchUserProfile = async () => {
    const response = await request({
        url: `/profile/${parsedValue.id}`
    });
    if (response.status === 200) {
        return response?.data?.data;
    }
    return [];
}


const Settings = () => {
    const { data, isLoading, isError } = useQuery("userProfile", fetchUserProfile);
    if (isLoading) {
        return (
            <div>loading...</div>
        )
    }
    return (
        <form action="">
            <div className='flex items-center justify-center w-full sm:w-[70%] lg:w-[44%]  mx-auto mb-3'>
                <div className="flex flex-col items-center justify-center gap-6 w-full">
                    <SettingsHeader data={data} />
                    <InputsForm data={data} />
                </div>
            </div>
        </form>
    )
}

const SettingsHeader = ({ data }) => {
    return (
        <div className='flex  flex-col items-center gap-2'>
            <div className='relative w-fit'>
                {data?.Profile_photo === null ?
                    <div className="relative w-[80px] h-[80px] overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                        <svg className="absolute w-[80px] h-[80px] text-gray-400 -left-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"></path></svg>
                    </div>
                    :
                    <img src={Avatar} alt="test" className='w-[100px] h-[100px] rounded-full ' />
                }
                <FaCamera className='bg-mainColor p-1 text-white rounded-full w-[20px] h-[22px] shadow-md absolute transform translate-x-[-50%] translate-y-[-50%] bottom-[0] right-[-10px] cursor-pointer' />
            </div>
            <h5 className='text-base md:text-xl lg:text-2xl font-bold'>{data?.First_name} {data?.Last_name}</h5>

        </div>
    )
}

const InputsForm = ({ data }) => {

    const handleChange = () => {

    }
    return (
        <div className='w-full flex flex-col justify-center items-center '>
            <SettingsInput label={"الأسم الأول:"} value={`${data?.First_name}`} onChange={handleChange} id={"name"} name={"name"} type={"text"} />
            <SettingsInput label={"الأسم الأخير:"} value={`${data?.Last_name}`} onChange={handleChange} id={"name"} name={"name"} type={"text"} />

            <SettingsInput label={"السيرة الذاتية :"} value={""} onChange={handleChange} id={"title"} name={"title"} />
            <SettingsInput label={"البريد الالكتروني :"} value={`${data?.email}`} onChange={handleChange} id={"email"} name={"email"} type={"email"} />
            {/* <div className='flex items-center gap-3 justify-between w-full'>
                <SettingsInput label={"كلمة المرور الحالية :"} value={"khadra"} onChange={handleChange} id={"password"} name={"password"} type={"password"} />
                <SettingsInput label={"كلمة المرور الجديدة :"} value={"khadra"} onChange={handleChange} id={"newPassword"} name={"newPassword"} type={"password"} />
            </div> */}
            <button className={`text-center bg-mainColor text-white hover:bg-white border-2 hover:border-mainColor hover:text-mainColor duration-500 rounded-lg w-[200px] py-1 px-2 `}>حفظ التغيرات</button>
        </div>
    )
}
export default Settings