import React, { useEffect, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { useOutSideClick } from "../../../hooks/useOutSideClick";

const CustomSelect = ({ options, onSelect, label, value, width }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(label ? label : options[0]);



    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    // ! handle outside click
    const handleCloseDropdown = () => {
        setIsOpen(false)
    }
    const ref = useOutSideClick(handleCloseDropdown);

    // ! handle select option
    const handleSelectChange = (option) => {
        setSelectedOption(option);
        onSelect(option);
        setIsOpen(false);
    };

    return (
        <div className={`${width} `}>
            <label className="block mb-1 text-md font-medium text-mainColor">
                {label}
            </label>
            <div
                ref={ref}
                className={` h-[40px]  px-2 cursor-pointer  rounded-md relative w-full flex items-center justify-between border-2 border-mainColor`}
                onClick={toggleDropdown}
            >
                <div
                    className={`h-6 cursor-pointer text-mainColor ${value ? " opacity-55" : ""
                        }`}
                    onClick={toggleDropdown}
                >
                    {selectedOption}
                </div>

                {isOpen && (
                    <ul className="absolute top-full left-0 w-full rounded-lg   mt-[2px] bg-white z-50 border-2 border-mainColor">
                        {options.map((option, i) => (
                            <li
                                className="p-2 cursor-pointer  hover:bg-mainColor hover:text-white"
                                key={i}
                                onClick={() => handleSelectChange(option)}
                            >
                                {option}
                            </li>
                        ))}
                    </ul>
                )}

                {isOpen ? (
                    <FaAngleUp className="text-mainColor" />
                ) : (
                    <FaAngleDown className="text-mainColor" />
                )}
            </div>
        </div>
    );
};

export default CustomSelect;
