import React, { useState, useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import ContentWrapper from '../../components/virtualWallet/common/ContentWrapper'
import VirtualButton from '../../components/virtualWallet/common/VirtualButton'
import { useNavigate } from 'react-router-dom'
import StockContainer from '../../components/virtualWallet/charts/StockContainer'
import { request } from '../../utils/axios'
import { useQuery } from 'react-query'
import { useOutSideClick } from '../../hooks/useOutSideClick';
import VirtualInput from '../../components/virtualWallet/common/VirtualInput';
import toast from 'react-hot-toast';

const fetchCompanies = async () => {
    const response = await request({
        url: `/companies-data`
    });
    if (response.status === 200) {
        return response?.data;
    }
    return [];
};

// const fetch budget 
const fetchBudget = async () => {
    const response = await request({
        url: `/userBudget`
    });
    if (response.status === 200) {
        return response?.data;
    }
    return [];
};
const VirtualWallet = () => {
    const [openPopup, setOpenPopup] = useState(false);
    const { data, isLoading } = useQuery("companies", fetchCompanies);
    const { data: userBudget, isLoading: loading } = useQuery("userBudget", fetchBudget);
    useEffect(() => {
        AOS.init({
            duration: 500,
            once: true,
        });
    }, []);

    // ======= handle close popup =======
    const handleClosePopup = () => {
        setOpenPopup(false);
    };
    const ref = useOutSideClick(handleClosePopup);


    if (isLoading && loading) {
        return (<div>loading....</div>)
    }

    return (
        <div className='mt-20'>
            <div className="flex gap-x-8 gap-y-2 flex-col md:flex-row justify-center">
                <ContentWrapper className={"flex flex-col gap-2 items-center justify-center"}>
                    {
                        userBudget?.budget < 0.000001 ? (
                            <VirtualButton text={"أضف مبلغ الاستثمار"} action={() => { setOpenPopup(true) }} />
                        ) :
                            (
                                <>
                                    <div className='flex items-center gap-3 text-lg'>
                                        <span className='text-white bg-mainColor p-1 rounded-lg'>EGP</span>
                                        <p className='text-mainColor font-bold'>المبلغ المتاح</p>
                                    </div>

                                    <h5 className='font-bold text-3xl text-mainColor'>  <bdi>{userBudget?.budget}  EGP</bdi> </h5>
                                </>
                            )
                    }

                </ContentWrapper>
                <ButtonContainer />
            </div>
            <p className='mt-5 font-semibold text-base md:text-lg'>أسعار الذهب والأسهم</p>
            <div className='md:px-8'>

                <StockContainer data={data} />
            </div>
            {openPopup && <Popup ref={ref} closePopup={handleClosePopup} />}
        </div>
    )
}

const ButtonContainer = () => {
    const navigate = useNavigate();
    const [value, setValue] = useState("")
    const handleChange = (e) => {
        e.preventDefault();
        setValue(e.target.value)
    }
    return (
        <div className='flex flex-col sm:flex-row md:flex-col items-center justify-around gap-3 flex-[.4]'>
            <VirtualButton text="سجل إستثماري" action={() => { navigate("/virtual-wallet/investment-report") }} />
            <VirtualButton text="بيع إستثماري" action={() => { navigate("/virtual-wallet/selling-investments") }} />
        </div>
    )
}


const Popup = ({ ref, closePopup }) => {
    const [budget, setBudget] = useState(null);

    const handleHeaderClick = (event) => {
        event.stopPropagation();
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setBudget(value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("budget", budget)

        const response = await request({
            url: "/userBudget", method: "post", data: formData
        })

        if (response.status === 200) {
            toast.success("تم إضافة الملبغ إالي محفظتك");
            closePopup()
        } else {
            toast.error("يوجد خطأ ما")
        }
    }
    return (
        <div className="z-[1000] fixed top-0 left-0 w-full h-full text-center flex items-center justify-center bg-black bg-opacity-50" onClick={closePopup}>
            <form onSubmit={handleSubmit}>

                <div
                    ref={ref}
                    data-aos="zoom-in"
                    data-aos-once="true"
                    className="w-[300px] sm:w-[345px] z-[1000] relative   rounded-xl bg-white p-5 flex flex-col justify-center items-center gap-y-3"
                    onClick={handleHeaderClick}
                >
                    <VirtualInput placeholder={"EGP"} label={"ادخل المبلغ في المحفظه"} value={budget} type={"number"} name={"budget"} onChange={handleInputChange} />
                    <VirtualButton text=" إرسال" />


                </div>
            </form>
        </div>
    )
}
export default VirtualWallet