import BgProfile from "../../../../../assets/bg_profile.png";
import { useLocation } from 'react-router-dom';
const HeaderProfile = ({ userData, followers, followees }) => {
    const { pathname } = useLocation();
    const storedValue = localStorage.getItem('userInvesto');
    const parsedValue = JSON.parse(storedValue);
    return (
        <div className='bg-white rounded-2xl shadow-xl'>
            <img src={BgProfile} alt="bg" className='w-full rounded-t-2xl' />
            <div className="relative">
                {
                    userData?.Profile_photo === null ?
                        <div className=" absolute transform translate-x-[-50%] translate-y-[-50%] top-[-10px] right-[-20px] w-[80px] h-[80px] overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600 flex items-center justify-center">
                            <svg className="absolute w-[80px] h-[80px] text-gray-400 -left-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"></path></svg>
                        </div> :
                        <img src={userData?.Profile_photo} alt="avatar" className='absolute transform translate-x-[-50%] translate-y-[-50%] top-[-10px] right-[-20px] w-[80px] h-[80px] rounded-full object-cover' />
                }
                
            </div>

            <div className="flex  flex-col gap-2 py-4 border-b border-[#d8d8d8] px-2 lg:px-5 mt-[30px]" >
                <h5 className='text-base md:text-xl lg:text-2xl  font-semibold'> {userData?.First_name} {userData?.Last_name}</h5>
                <p className='text-base  font-semibold text-black opacity-75'> {userData?.bio} </p>
                <div className='flex items-center gap-3'>
                    <p className='flex items-center gap-1'>
                        {followees = null ? 0 : followees?.length}
                        <span className='text-sm text-black opacity-50'>يتابع</span>
                    </p>
                    <p className='flex items-center gap-1'>
                        {followers = null ? 0 : followers?.length}
                        <span className='text-sm text-black opacity-50'>متابع</span>
                    </p>
                </div>
            </div>

        </div>
    )
}

export default HeaderProfile;