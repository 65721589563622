import React from "react";
import EducationalSidebar from "../../components/educationalPlatForm/EducationalSidebar";
import VideoCard from "../../components/educationalPlatForm/videos/VideoCard";
import { requestEdu } from "../../utils/axiosEdu";
import { useQuery } from "react-query";

const fetchVideos = async () => {
  const response = await requestEdu({
    url: "/videos/?format=json",
  });
  if (response.status === 200) {
    return response.data;
  }
  return [];
}
const EduVideos = () => {
  return <EducationalSidebar><VideoData /></EducationalSidebar>;
};
const VideoData = () => {
  const { data, isLoading, isError } = useQuery("videos", fetchVideos);
  return (
    <div className="mt-5 sm:mt-0">

      {data?.map((item, i) => (

        <VideoCard item={item} index={i}  />))}


    </div>
  )
}
export default EduVideos;
