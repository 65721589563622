import { createContext, useContext, useState } from "react";

const VirtualWalletContext = createContext();


const VirtualData = [
    {
        id: 1,
        company_name: "appel",
        close_price: 1800,
        open_close: 1600,
        time_frame: [123, 134, 155, 145, 120, 123, 150],
        predicted_values: [126, 130, 145, 140, 125, 127, 140],
        stoke_value: 1700
    },
    {
        id: 2,
        company_name: "appel",
        close_price: 1800,
        open_close: 1600,
        time_frame: [123, 134, 155, 145, 120, 123, 150],
        predicted_values: [126, 130, 145, 140, 125, 127, 140],
        stoke_value: 1700
    },
    {
        id: 3,
        company_name: "appel",
        close_price: 1800,
        open_close: 1600,
        time_frame: [123, 134, 155, 145, 120, 123, 150],
        predicted_values: [126, 130, 145, 140, 125, 127, 140],
        stoke_value: 1700
    },
    {
        id: 4,
        company_name: "appel",
        close_price: 1800,
        open_close: 1600,
        time_frame: [123, 134, 155, 145, 120, 123, 150],
        predicted_values: [126, 130, 145, 140, 125, 127, 140],
        stoke_value: 1700
    },
    {
        id: 5,
        company_name: "appel",
        close_price: 1800,
        open_close: 1600,
        time_frame: [123, 134, 155, 145, 120, 123, 150],
        predicted_values: [126, 130, 145, 140, 125, 127, 140],
        stoke_value: 1700
    },
    {
        id: 6,
        company_name: "appel",
        close_price: 1800,
        open_close: 1600,
        time_frame: [123, 134, 155, 145, 120, 123, 150],
        predicted_values: [126, 130, 145, 140, 125, 127, 140],
        stoke_value: 1700
    },
    {
        id: 7,
        company_name: "appel",
        close_price: 1800,
        open_close: 1600,
        time_frame: [123, 134, 155, 145, 120, 123, 150],
        predicted_values: [126, 130, 145, 140, 125, 127, 140],
        stoke_value: 1700
    },
    {
        id: 8,
        company_name: "appel",
        close_price: 1800,
        open_close: 1600,
        time_frame: [123, 134, 155, 145, 120, 123, 150],
        predicted_values: [126, 130, 145, 140, 125, 127, 140],
        stoke_value: 1700
    }
]
const VirtualWalletProvider = ({ children }) => {
    const [data, setData] = useState(VirtualData)
    const value = {
        data,
        setData
    }

    return (
        <VirtualWalletContext.Provider value={value}>
            {children}
        </VirtualWalletContext.Provider>
    );

};

const useVirtualWallet = () => {
    return useContext(VirtualWalletContext);
};

export { VirtualWalletProvider, useVirtualWallet };