import React from 'react'

const ContentWrapper = ({ children, className }) => {
    return (
        <div className={`${className} bg-white shadow min-h-[150px] rounded-2xl w-full p-4`}>
            {children}
        </div>
    )
}

export default ContentWrapper