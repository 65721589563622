import React, { useState } from 'react'
import InvestmentReportCard from '../../components/virtualWallet/investmentReport/InvestmentReportCard'
import ContentWrapper from '../../components/virtualWallet/common/ContentWrapper'
import { useNavigate } from 'react-router-dom'
import VirtualButton from '../../components/virtualWallet/common/VirtualButton'
import { request } from '../../utils/axios'
import { useQuery } from 'react-query'



const fetchInvestmentBuy = async () => {
    const response = await request({
        url: `/buyHistory`
    });
    if (response.status === 200) {
        return response?.data?.data;
    }
    return [];
};
const fetchInvestmentSell = async () => {
    const response = await request({
        url: `/salesHistory`
    });
    if (response.status === 200) {
        return response?.data?.data;
    }
    return [];
};


// const fetch budget 
const fetchBudget = async () => {
    const response = await request({
        url: `/userBudget`
    });
    if (response.status === 200) {
        return response?.data;
    }
    return [];
};

const InvestmentReport = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('sell');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const { data: userBudget } = useQuery("userBudget", fetchBudget);


    return (
        <div className="flex gap-6 flex-col lg:flex-row">
            <div className='flex-[.2] flex flex-col gap-4'>
                <ContentWrapper className={"flex items-center justify-center h-fit"}>
                    <div className='flex flex-col  items-center justify-around h-[100px]'>

                        <div className='flex items-center gap-2'>
                            <span className='text-white bg-mainColor p-1 rounded-md'>EGP</span>
                            <p className='text-mainColor font-bold'>المبلغ المتاح</p>
                        </div>
                        <h5 className='font-bold text-3xl text-mainColor text-nowrap'>  <bdi>{userBudget?.budget}  EGP</bdi> </h5>
                    </div>
                </ContentWrapper>

                <VirtualButton text={"المحفظة الوهمية"} action={() => navigate("/virtual-wallet")} />
                <VirtualButton text={"بيع استثماراتي"} />

            </div>

            <ContentWrapper className={"flex-1"}>

                <p className='text-mainColor font-bold text-center mt-2 mb-8 text-base md:text-lg '>سجل استثماراتي</p>

                <div className='flex items-center justify-center gap-x-5 text-mainColor'>
                    <span className={`cursor-pointer ${activeTab === "sell" ? "text-mainColor pb-1 w-fit border-b-2 border-mainColor" : "text-black"}`} onClick={() => {
                        handleTabChange('sell');
                    }}>سجل البيع</span>
                    <span className={`cursor-pointer ${activeTab === "buy" ? "text-mainColor pb-1 w-fit border-b-2 border-mainColor" : "text-black"}`} onClick={() => {
                        handleTabChange('buy');
                    }}>سجل الشراء</span>

                </div>

                {activeTab === 'sell' && <SellHistory />}
                {activeTab === 'buy' && <BuyHistory />}

            </ContentWrapper>

        </div>
    )
}
const SellHistory = () => {
    const { data: sellData, isLoading } = useQuery("sellData", fetchInvestmentSell);
    return (
        <>

            {sellData?.map(item => (
                <div key={item?.id}>
                    <InvestmentReportCard item={item} />
                </div>
            ))}
        </>
    )
}

const BuyHistory = () => {

    const { data: buyData, isLoading: loading } = useQuery("buyData", fetchInvestmentBuy);

    return (
        <>

            {buyData?.map(item => (
                <div key={item?.id}>
                    <div className='bg-[#ECECEC] rounded-xl shadow-xl md:h-36 relative my-6'>
                        <div className='p-2 md:p-6  flex flex-col justify-between h-full'>

                            <h5 className='font-bold text-base md:text-lg'>{item?.company}</h5>
                            <span className="absolute bg-mainColor text-white left-0 top-0 md:top-[20%] py-1 px-2 rounded-tr-3xl rounded-br-3xl w-[80px] text-[14px] text-center">{item?.investment_type}</span>
                            <div className='flex items-center justify-between flex-wrap gap-3'>
                                <div className='flex items-center gap-1'>
                                    <p className=' text-mainColor font-semibold text-sm md:text-base'>قيمة الاستثمار :</p>
                                    <span className='text-xs sm:text-sm md:text-base '>{item?.final_price} EGP</span>
                                </div>
                                <div className='flex items-center gap-1'>
                                    <p className=' text-mainColor font-semibold text-sm md:text-base'>بمعدل أسهم  :</p>
                                    <span className='text-xs sm:text-sm md:text-base '>{item?.number_of_stocks} سهم  </span>
                                </div>
                                {/* // <div className='flex items-center gap-1'>
                    //     <p className=' text-mainColor font-semibold text-sm md:text-base'>نسبة العائد من البيع :</p>
                    //     <span className='text-xs sm:text-sm md:text-base '>% {item.sale_value}</span>
                    // </div>
                    // <div className='flex items-center gap-1'>
                    //     <p className=' text-mainColor font-semibold text-sm md:text-base'>قيمة العائد الاستثماري :</p>
                    //     <span className='text-xs sm:text-sm md:text-base '>{item.buy_value} EGP</span>
                    // </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default InvestmentReport 