import { createContext, useContext, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
// import { toast } from "react-toastify";

const RecommendationContext = createContext();

const RecommendationProvider = ({ children }) => {
    const [response, setResponse] = useState(null);

    const lang = localStorage.getItem("lang")
        ? JSON.parse(localStorage.getItem("lang"))
        : "ar";

    const authToken = localStorage.getItem("tokenInvesto")
        ? JSON.parse(localStorage.getItem("tokenInvesto"))
        : null;

    const client = axios.create({
        baseURL: 'https://flexible-modest-walrus.ngrok-free.app',
        headers: {
            "Content-Type": "application/json",
            lang,
            "Accept-Language": lang,
            "Access-Control-Allow-Credentials": true,
            "x-api-key": "0FcBOe64FIFkBkNkA",
            Authorization: authToken ? `Bearer ${authToken}` : null,
        },
    });
    const request = async (formData) => {
        try {
            const response = await client.post('/predict', formData);
            setResponse(response.data);
            toast.success('تم التوقع بنجاح');
            return response.data;
        } catch (error) {
            if (error.response) {
                console.error('Request failed with status:', error.response.status);
                console.error('Response data:', error.response.data);
                if (error.response.status === 404) {
                    toast.error('Server not found. Please check the URL.');
                } else if (error.response.status === 500) {
                    toast.error('Internal server error. Please try again later.');
                } else {
                    toast.error(`Request failed: ${error.response.data.message}`);
                }
            } else if (error.request) {
                console.error('No response received:', error.request);
                toast.error('No response from server. Please try again later.');
            } else {
                console.error('Error:', error.message);
                toast.error('An unexpected error occurred.');
            }
            throw error;
        }
    };
    const value = {
        response, request, setResponse
    }

    return (
        <RecommendationContext.Provider value={value}>{children}</RecommendationContext.Provider>
    )
}

export const useRecommendation = () => {
    return useContext(RecommendationContext)
}

export default RecommendationProvider

