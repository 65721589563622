import React, { useEffect, useState } from 'react'
import CommunitySidebar from "../../components/socialCommunity/core/CommunitySidebar.jsx";
import CreatePost from "../../components/socialCommunity/CreatePost.jsx";
import Post from "../../components/socialCommunity/core/post/Post.jsx";
import ProfileCard from '../../components/socialCommunity/core/ProfileCard';
import FollowSuggestionsCard from '../../components/socialCommunity/core/FollowSuggestionsCard';
import HeaderProfile from '../../components/socialCommunity/core/profile/HeaderProfile.jsx';

// import
const Profile = () => {
    const [openMenu, setOPenMenu] = useState(false);
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);
    const handleToggle = () => {
        setOPenMenu(prev => !prev)
    }
    const handleClose = () => {
        setOPenMenu(false)
    }

    const handleResize = () => {
        setIsLargeScreen(window.innerWidth >= 1024);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <CommunitySidebar action={handleToggle} >
            <div className='flex justify-between gap-8'>
                <div className='flex-1 '>
                    <HeaderProfile />
                    <Post />
                </div>

                {/** left side */}
                <LeftSide isLargeScreen={isLargeScreen} handleClose={handleClose} openMenu={openMenu} />
            </div>

        </CommunitySidebar >
    )
}
const LeftSide = ({ openMenu, handleClose, isLargeScreen }) => {
    return (
        <>
            <div
                className={`absolute top-0 bottom-0 left-0 right-0 bg-black opacity-50 cursor-pointer ${openMenu ? 'block' : 'hidden'} ${window.innerWidth >= 1024 && "hidden"}`}
                onClick={handleClose}
            />
            <div
                className={`flex-[.44] flex flex-col gap-8 ${isLargeScreen ? '' : 'fixed'} left-0 top-[60px] bottom-0 bg-white lg:bg-transparent p-4 lg:p-0 duration-300  ${openMenu || isLargeScreen ? 'left-0' : 'left-[-550px]'
                    } overflow-y-auto mb-3`}
            >
                <ProfileCard />

                {/* <FollowSuggestionsCard /> */}
            </div>
        </>
    )
}
export default Profile