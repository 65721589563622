import React from 'react'

const SettingsInput = ({ id, label, type, name, value, onChange }) => {
    return (
        <div className='w-full my-4'>
            <label htmlFor={id} className='block mb-[6px] font-semibold text-[15px]'>{label}</label>
            {type ?
                <input type={type} value={value} onChange={onChange} name={name} className={`w-full text-[15px] text-black opacity-75 border border-[#9B9B9B80] rounded-lg py-1 px-2 bg-white`} id={id} />
                :
                <textarea value={value} onChange={onChange} name={name} className={`w-full h-[100px] text-[15px] text-black opacity-75 border border-[#9B9B9B80] rounded-lg py-1 px-2 bg-white`}>

                </textarea>
            }
        </div>
    )
}

export default SettingsInput