import React from 'react'
import MainBtn from '../../utils/MainBtn'

const ContactUsForm = () => {
    return (
        <div className='my-14 flex items-center justify-center flex-col gap-y-8'>
            <Title/>
            <Form/>
        </div>
    )
}

const Title = ( ) =>{
    return(
        <div className='text-center'>
            <h2 className='py-4 text-3xl font-bold'>اترك رسالتك</h2>
            <p className=' opacity-75'>لن يتم نشر عنوان بريدك الإلكتروني</p>
        </div>
    )
}

const Form = () => {
    return(
        <div className='w-[270px] md:w-[400px]'>
            <form>
                <div className='flex flex-col '>
                    <div className='flex flex-col  gap-y-3'>
                        <label htmlFor="" className='font-bold'>الرسالة </label>
                        <textarea className='rounded-lg p-2 border border-[#9B9B9B80] h-[120px]'></textarea>
                    </div>
                    <SingleInput label="الأسم :" type="text"/>
                    <SingleInput type="email" label='البريد الالكتروني :'/>
                    <MainBtn text="إرسال" textColor="text-white" hoverTextColor="hover:text-mainColor" bg="bg-mainColor" hoverBg="hover:bg-white"/>
                </div>
            </form>
        </div>
    )
}

const SingleInput = ({label , onChange , type }) => { 
    return (
        <div className="flex flex-col gap-y-2  my-3">
            <label htmlFor="" className="font-bold">
            {label}
            </label>
            <input
            type = {type}
            className = "outline-none rounded-lg  border border-[#9B9B9B80] px-2 py-1 h-[40px]" 
            />
        </div>
    )
}
export default ContactUsForm