import React, { useState } from "react";
import { GrHomeRounded } from "react-icons/gr";
import { IoBookOutline, IoSettingsOutline } from "react-icons/io5";
import { CiBookmarkMinus } from "react-icons/ci";
import { IoPeopleOutline } from "react-icons/io5";
import { CiCreditCard1 } from "react-icons/ci";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/logo.svg";
const sideIcons = [
    {
        id: 1,
        icon: GrHomeRounded,
        link: "/",
    },
    {
        id: 2,
        icon: IoBookOutline,
        link: "/education/courses",
    },
    {
        id: 3,
        icon: CiCreditCard1,
        link: "/virtual-wallet",
    },
    {
        id: 4,
        icon: IoPeopleOutline,
        link: "/community",
    },
    // {
    //     id: 5,
    //     icon: CiBookmarkMinus,
    //     link: "/login",
    // },
    {
        id: 6,
        icon: IoSettingsOutline,
        link: "/settings",
    },
];
const Sidebar = () => {
    const { pathname } = useLocation();
    const [activeNav, setActiveNav] = useState(pathname);

    const handleActiveLink = (index) => {
        setActiveNav(index);
    };

    return (
        <div>
            <aside
                id="logo-sidebar"
                className="fixed top-0 right-0 z-40 w-[55px]  h-screen "
                aria-label="Sidebar"
            >
                <div className="h-full py-4  bg-mainColor">
                    <Link to="/" className="flex items-center ps-2.5 mb-5">
                        <img src={Logo} className="h-6  sm:h-7" alt=" investo_Logo" />
                    </Link>
                    <ul className="space-y-2 pt-2 font-medium flex flex-col pr-2 justify-center items-center">
                        {sideIcons.map((item, i) => {
                            const IconComponent = item.icon;
                            return (
                                <>
                                    <li
                                        onClick={() => handleActiveLink(item.link)}
                                        className={`${activeNav === item.link
                                            ? `bg-[#ececec] w-full rounded-3xl activeIcon relative `
                                            : null
                                            }  `}
                                    >
                                        <Link
                                            to={item.link}
                                            className={`flex items-center p-2 text-white   ${activeNav === item.link
                                                ? "text-mainColor bg-[#ececec] rounded-r-3xl group"
                                                : "text-white"
                                                }`}
                                        >
                                            <IconComponent
                                                className={`w-5 h-5  transition duration-75
												${activeNav === item.link ? "text-mainColor" : "text-white"
                                                    } 
											`}
                                            />
                                        </Link>
                                    </li>
                                    {item.id === 4 && <span className="bg-white h-[1px] w-[80%] my-3"></span>}
                                </>
                            );
                        })}
                    </ul>
                </div>
            </aside>
        </div>
    );
};

export default Sidebar;
