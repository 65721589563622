import React, { useEffect, useState } from "react";
import EducationalSidebar from "../../components/educationalPlatForm/EducationalSidebar";
import DocumentCard from "../../components/educationalPlatForm/docs/DocumentCard";
import AOS from "aos";
import { DocumentsData } from "../../data/data";
import Pagination from "../../utils/Pagination";
import { requestEdu } from "../../utils/axiosEdu";
import { useQuery } from "react-query";


const fetchArticles = async () => {
    const response = await requestEdu({
        url: "/articles/?format=json",
    });
    if (response.status === 200) {
        return response.data;
    }
    return [];
}
const Docs = () => {

    return <EducationalSidebar>
        <DocsData />
    </EducationalSidebar>;
};


const DocsData = () => {
    const { data, refetch, isLoading, isError } = useQuery("articles", fetchArticles);

    // ! handle pagination 
    const pageLimit = 9;
    const [pageCards, setPageCards] = useState([]);

    // ! handle animation
    useEffect(() => {
        AOS.init({
            duration: 300,
        });
    }, []);
    const animationDelayFactor = 200;

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error loading data</div>;
    }
    console.log(data);
    return (
        <>
            <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-8     gap-x-8 xl:gap-x-16 ">

                {data?.map((item, i) => (
                    <DocumentCard item={item} index={i} animationDelayFactor={animationDelayFactor} />
                ))}

            </div>

            {/* <Pagination
                items={DocumentsData}
                pageLimit={pageLimit}
                setPageItems={setPageCards}
            /> */}

        </>
    )
}
export default Docs;
