import React, { useState } from "react";
import AuthInput from "../../components/auth/AuthInput";
import { MdOutlineEmail } from "react-icons/md";
import { CiLock } from "react-icons/ci";
import { FormProvider, useForm } from "react-hook-form";
import Facebook from "../../../assets/Facebook.svg";
import Google from "../../../assets/Google.svg";
import { login_email_validation, password_validation } from "../../utils/auth/inputValidations";
import AuthModel from "../../components/auth/AuthModel";
import AuthBtn from "../../components/auth/AuthBtn";
import ForgetPassModel from "../../components/auth/ForgetPassModel";
import Register from "../../components/auth/Register";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { request } from "../../utils/axios";
import { useMutation } from "react-query";
import { login } from "../../redux/auth"
import toast from "react-hot-toast";

const Login = () => {
    const [openForgetPass, setOpenForgetPass] = useState(false);
    const [openRegister, setOpenRegister] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const methods = useForm();

    //====== handle form functions ========
    const sendData = (data) => {
        return request({ url: "/login", method: "post", data });
    };

    const { mutate, isLoading } = useMutation(sendData, {
        onSuccess: (data) => {
            const userData = data?.data?.data?.user;
            const token = data?.data?.data?.token;
            dispatch(login({ userData, token }));
            toast.success("تم تسجيل دخولك بنجاح")
            navigate("/");

        },
        onError: (error) => {
            console.error("Login failed:", error);
        }
    })
    const onSubmit = methods.handleSubmit(async (data) => {

        mutate(data);
    });

    // ======= handle open forget Popup ======
    const handleOpenForgetPass = () => {
        setOpenForgetPass(true);
    };
    // ======= handle open Register Popup ======
    const handleOpenRegister = () => {
        setOpenRegister(true);
    };
    return (
        <>
            <FormProvider {...methods}>
                <AuthModel>
                    <div className="   ">
                        <form>
                            <AuthInput
                                {...login_email_validation}
                                Icon={MdOutlineEmail}
                                width="w-[280px] sm:w-[320px] md:w-[360px]"
                            />
                            <AuthInput
                                {...password_validation}
                                Icon={CiLock}
                                width="w-[280px] sm:w-[320px] md:w-[360px]"
                            />
                            <div className="flex justify-between items-center ">
                                <label>
                                    <input type="checkbox" className="px-1 mx-1" />
                                    تذكرني
                                </label>
                                <span
                                    className="text-[#1C31F0] cursor-pointer hover:drop-shadow-lg"
                                    onClick={handleOpenForgetPass}
                                >
                                    نسيت كلمة المرور؟
                                </span>
                            </div>
                            {openForgetPass ? (
                                <ForgetPassModel setOpenForgetPass={setOpenForgetPass} />
                            ) : null}
                            <AuthBtn
                                text="تسجيل الدخول"
                                action={onSubmit}
                                width="w-[280px] sm:w-[320px] md:w-[360px]"
                            />
                            <div className="flex flex-col items-center juc">
                                <p className="my-4 ">أو سجل دخول باستخدام </p>

                                <div className="flex justify-center items-center">
                                    <img
                                        src={Google}
                                        alt="google"
                                        className=" px-1 w-[40px] h-[40px]"
                                    />
                                    <span>أو</span>
                                    <img
                                        src={Facebook}
                                        alt="facebook"
                                        className=" px-1 w-[40px] h-[40px]"
                                    />
                                </div>

                                <p className="my-4">
                                    ليس لديك حساب؟
                                    <span
                                        className="text-[#1C31F0] cursor-pointer hover:drop-shadow-lg font-semibold pr-1"
                                        onClick={handleOpenRegister}
                                    >
                                        إنشاء حساب
                                    </span>
                                </p>
                                {openRegister ? <Register setOpenRegister={setOpenRegister} /> : null}
                            </div>
                        </form>
                    </div>
                </AuthModel>
            </FormProvider>
        </>
    );
};

export default Login;
