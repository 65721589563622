import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import ResetPassImage from "../../../assets/resetPass.png";
import AuthInput from "./AuthInput";
import AuthBtn from "./AuthBtn";
import { password_validation } from "../../utils/auth/inputValidations";
import { MdOutlineEmail } from "react-icons/md";

const ResetPassword = () => {
  //====== handle form functions ========
  const methods = useForm();
  const onSubmit = methods.handleSubmit(async (data) => {
    methods.reset(data);
  });
  return (
    <div className="flex flex-col items-center">
      <img src={ResetPassImage} alt="ForgetPass" />

      <span className="font-bold text-xl ">مرحبا بعودتك !</span>
      <p className="text-black text-opacity-75 text-center text-[14px] py-2">
        قم الان بتغير كلمة المرور
      </p>
      <FormProvider {...methods}>
        <form>
          <AuthInput
            {...password_validation}
            Icon={MdOutlineEmail}
            width="sm:w-[320px] w-[285px]"
          />
          <AuthBtn
            text="إرسال"
            width="sm:w-[320px] w-[285px]"
            action={onSubmit}
          />
        </form>
      </FormProvider>
    </div>
  );
};

export default ResetPassword;
