import React from 'react'
import DocCardImage from "../../../../assets/docCard.png"
import { useNavigate } from 'react-router-dom'
const DocumentCard = ({ item, animationDelayFactor, index }) => {
    const navigate = useNavigate();

    return (
        <div className='flex flex-col bg-white rounded-[30px] cursor-pointer'
            data-aos="fade-up"
            data-aos-delay={index * animationDelayFactor}
            // onClick={() => navigate(`/education/doc/${item.id}`)}
        >

            <div className='relative'>
                <img src={item.image} alt="course" className='w-full rounded-t-[30px]  border-t-[10px]
                border-r-[10px] border-l-[10px] border-mainColor h-[240px]' />

                <span className='text-white bg-mainColor rounded-tr-[16px] py-1 px-2 absolute left-1 bottom-0 w-fit font-bold'>
                    investo
                </span>
            </div>

            <div className=' p-6'>
                <h3 className='text-2xl font-semibold text-mainColor pb-6'>{item.title}</h3>
                <span className=''>{item.level}</span>
                <p className="text-lg text-ellipsis pt-6 overflow-hidden ">{item.description} </p>
            </div>
        </div>
    )
}

export default DocumentCard