import React from 'react';

const CommunityInput = ({ placeholder, action, name, value, isFocus, setFocus }) => {
    return (
        <textarea
            placeholder={placeholder}
            value={value}
            onChange={action}
            name={name}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            className={`bg-[#ECECEC] w-full p-[6px] text-[15px] text-[#9B9B9B] duration-300 resize-none overflow-y-auto 
                        ${isFocus ? "rounded-lg h-[100px]" : "rounded-2xl h-[40px]"} ${isFocus ? "text-right" : ""}`}
            style={{ lineHeight: '1.5' }}
        />
    )
}

export default CommunityInput;
