import { Route, Routes } from "react-router-dom";
import Root from "./actions/layout/Root";
import Login from "./actions/pages/auth/Login";
import Charts from "./actions/pages/Charts";
import Recommendation from "./actions/pages/Recommendation";
import Courses from "./actions/pages/educationalPlatform/Courses";
import Docs from "./actions/pages/educationalPlatform/Docs";
import EduVideos from "./actions/pages/educationalPlatform/EduVideos";
import SpecificDoc from "./actions/pages/educationalPlatform/SpecificDoc";
import VirtualWallet from "./actions/pages/virtualWallet/VirtualWallet";
import InvestmentReport from "./actions/pages/virtualWallet/InvestmentReport";
import SellingInvestments from "./actions/pages/virtualWallet/SellingInvestments";
import BuyStocks from "./actions/pages/virtualWallet/BuyStocks";
import MainPage from "./actions/pages/community/MainPage";
import Settings from "./actions/pages/settings/Settings";
import Profile from "./actions/pages/community/Profile";
import MyProfile from "./actions/pages/community/MyProfile";
import UserProfile from "./actions/pages/community/UserProfile";

function App() {
    return (
        <Routes>
            <Route path="/" element={<Root />}>
                <Route index element={<Recommendation />} />
                <Route path="/charts" element={<Charts />} />
                <Route path="/education/courses" element={<Courses />} />
                <Route path="/education/docs" element={<Docs />} />
                <Route path="/education/doc/:id" element={<SpecificDoc />} />
                <Route path="/education/videos" element={<EduVideos />} />
                <Route path="/virtual-wallet" element={<VirtualWallet />} />
                <Route path="/virtual-wallet/investment-report" element={<InvestmentReport />} />
                <Route path="/virtual-wallet/selling-investments" element={<SellingInvestments />} />
                <Route path="/virtual-wallet/buy-stocks/:company_name" element={<BuyStocks />} />
                <Route path="/community" element={<MainPage />} />
                <Route path="/community-profile" element={<Profile />} />
                <Route path="/community/my-profile/:id" element={<MyProfile />} />
                <Route path="/community/user-profile/:userId" element={<UserProfile />} />

                <Route path="/settings" element={<Settings />} />
            </Route>
            <Route path="/login" element={<Login />} />
        </Routes>
    )
}

export default App;
