// import { useTranslationHelper } from "./TranslationUtility";

// const t = useTranslationHelper();
export const name_validation = {
  name: "name",
  label: "name",
  type: "text",
  id: "name",
  placeholder: "الأسم",
  validation: {
    required: {
      value: true,
      message: "الحقل مطلوب",
    },
    maxLength: {
      value: 30,
      message: "الحد الأقصي 30 حرف",
    },
  },
};
export const first_name_validation = {
  name: "f_name",
  label: "الأسم الأول",
  type: "text",
  id: "firstName",
  // placeholder: "الأسم الأول",
  validation: {
    required: {
      value: true,
      message: "الحقل مطلوب",
    },
    maxLength: {
      value: 30,
      message: "الحد الأقصي 30 حرف",
    },
  },
};
export const last_name_validation = {
  name: "l_name",
  label: "الأسم الأخير",
  type: "text",
  id: "lastName",
  // placeholder: "الأسم الأخير",
  validation: {
    required: {
      value: true,
      message: "الحقل مطلوب",
    },
    maxLength: {
      value: 30,
      message: "الحد الأقصي 30 حرف",
    },
  },
};
export const desc_validation = {
  name: "description",
  label: "description",
  multiline: true,
  id: "description",
  placeholder: "write description ...",
  validation: {
    required: {
      value: true,
      message: "الحقل مطلوب",
    },
    maxLength: {
      value: 200,
      message: "200 characters max",
    },
  },
};

export const password_validation = {
  name: "password",

  type: "password",
  id: "password",
  placeholder: "الرقم السري",
  validation: {
    required: {
      value: true,
      message: "الحقل مطلوب",
    },
    minLength: {
      value: 8,
      message: "علي الأقل 8 احرف",
    },
  },
};
export const register_password_validation = {
  name: "password",
  label: "كلمة المرور",

  type: "password",
  id: "password",

  validation: {
    required: {
      value: true,
      message: "الحقل مطلوب",
    },
    minLength: {
      value: 8,
      message: "علي الأقل 8 احرف",
    },
  },
};
export const confirm_password_validation = {
  name: "password",
  label: "تأكيد كلمة المرور",

  type: "password",
  id: "password",

  validation: {
    required: {
      value: true,
      message: "الحقل مطلوب",
    },
    notequall: {
      value: true,
      message: "الرقم لا بطابق الرقم السري",
    },
  },
};
export const num_validation = {
  name: "phone",
  label: "number",
  type: "number",
  id: "num",
  placeholder: "رقم الهاتف",
  validation: {
    required: {
      value: true,
      message: "الحقل مطلوب",
    },
    pattern: {
      value: /^(010|011|012|015)\d{8,11}$/,
      message: "not Egyptian Phone Number",
    },
  },
};

export const login_email_validation = {
  name: "email",
  type: "email",
  id: "email",
  placeholder: "البريد الإلكتروني",
  validation: {
    required: {
      value: true,
      message: "الحقل مطلوب",
    },
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: "البريد غير صحيح",
    },
  },
};
export const register_email_validation = {
  name: "email",
  label: "البريد الإلكتروني",
  type: "email",
  id: "email",

  validation: {
    required: {
      value: true,
      message: "الحقل مطلوب",
    },
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: "البريد غير صحيح",
    },
  },
};

// subscription
export const age_validation = {
  name: "age",
  label: "Age",
  type: "number",
  id: "age",
  placeholder: "Age",
  validation: {
    required: {
      value: true,
      message: "required",
    },
    min: {
      value: 2,
      message: "Minimum rating is 2",
    },
    max: {
      value: 2,
      message: "Maximum rating is 2",
    },
  },
};
// comment inputs

// sendEvaluationValidations.js

export const comment_validation = {
  name: "comment",
  label: "comment",
  multiline: true,
  id: "comment",
  placeholder: "Write your comment...",
  validation: {
    required: {
      value: true,
      message: "Required",
    },
    maxLength: {
      value: 50,
      message: "50 characters max",
    },
  },
};

export const rating_validation = {
  name: "rating",
  label: "rating",
  type: "number",
  id: "rating",
  placeholder: "Enter your rating...",
  validation: {
    required: {
      value: true,
      message: "Required",
    },
    min: {
      value: 3,
      message: "Minimum rating is 1",
    },
    max: {
      value: 20,
      message: "Maximum rating is 5",
    },
  },
};


// ! national id validation
export const national_id = {
  name: "national_id",
  label: "الرقم القومي",
  type: "number",
  id: "national_id",
  validation: {
    required: {
      value: true,
      message: "Required",
    },
    minLength: {
      value: 14,
      message: "يجب أن يكون 14 رقم",
    },
    
  }
}